.customoverlay {
    position: relative; 
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
    // border: 1px solid #ccc; 
}
// .customoverlay:after {
//     content: "";
//     position: absolute;
//     margin-left: -12px;
//     left: 50%;
//     bottom: -12px;
//     width: 22px;
//     height: 12px;
//     background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png')
// }
// .customoverlay:nth-of-type(n) {
//     border: 0; 
//     box-shadow: 0px 1px 2px #888;
// }
// .customoverlay a {
//     display: block; 
//     text-decoration: none;
//     color: #000;
//     text-align: center;
//     border-radius: 6px;
//     font-size: 14px; 
//     font-weight: bold;
//     overflow: hidden;
//     background: #d95050;
//     background: #d95050 url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png) no-repeat right 14px center;
// }
.customoverlay .title {
    display: inline-block;
    text-align: center;
    background: #fff;
    // margin-right: 35px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 12px;
}