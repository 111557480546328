// Form
.input-form {
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &.required {
        .form-title {
            span::after {
                content: "*";
                position: absolute;
                top: -3px;
                margin-left: 4px;
                font-size: 18px;
                font-weight: 700;
                color: #ea5a00;
            }
        }
    }

    &.grid-row {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        label.form-title {
            display: inline-flex;
            align-items: center;
            flex-shrink: 0;
            width: 110px;
            font-size: 15px;
            font-weight: 500;
            line-height: 38px;
            color: #636363;
        }

        .form-field {

            .input-element,
            .select-element {
                width: 100%;
                border-radius: 4px;
            }
        }
    }

    label.form-title {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        margin-bottom: 8px;

        em {
            color: #f00;
        }
    }

    p.form-title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;

        span {
            position: relative;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-6);
        }

        a {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-5);
        }

        .form-checkbox {
            margin-top: 0;
        }
    }

    .btn-link {
        display: block;
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: var(--gray-scale-gray-10);
        text-align: right;
    }

    .form-field {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 38px;
        gap: 8px;

        &.grid-col {
            flex-direction: column;
            gap: 8px;
        }

        // default input
        .input-element {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 100%;
            height: 44px;
            // background-color: #f6f6f6;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid var(--gray-scale-gray-3);
            transition: border-color 0.3s, background-color 0.3s;
            // overflow: hidden;
            padding: 0 8px;

            &.disabled {
                border-color: var(--gray-scale-gray-3);
                background-color: var(--gray-scale-gray-2);
                pointer-events: none;

                input {
                    color: #888;
                }
            }

            &~.select-element {
                width: 120px;
                flex-shrink: 0;
                margin-left: 10px;
            }

            input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                font-size: 14px;
                font-weight: 400;
                color: var(--gray-scale-black);

                &.regiNum {
                    letter-spacing: 10px;
                    padding: 0 4px;

                    &#regiNum2 {
                        padding: 0 4px 0 22px;
                    }

                    ~i {
                        font-size: 14px;
                        font-weight: 500;
                        color: #cdcdcd;
                    }

                    ~.regiNumMasking {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 38%;
                        height: 100%;
                        padding: 0 16px 0 4px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 400;
                        color: #cdcdcd;
                        letter-spacing: 10px;
                        pointer-events: none;
                    }
                }
            }

            input::placeholder {
                color: var(--gray-scale-gray-9);
            }

            input:focus {
                outline: none;
            }

            &:focus-within {
                border-color: var(--primary-primary);
                background-color: #fff;
            }

            // text_notice (안내말)
            &.text_notice {
                input {
                    width: auto;
                }

                span {}
            }

            .input-asset {
                flex-shrink: 0;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: var(--gray-scale-gray-7);
            }

            .emphasis {
                font-size: 14px;
                font-weight: 500;
                color: #dc4e41;
            }
        }

        // input file
        .file-element {
            position: relative;
            display: flex;
            align-items: center;
            width: 360px;
            max-width: 100%;
            height: 48px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid var(--gray-scale-black);
            transition: border-color 0.3s;
            overflow: hidden;
            padding: 0 16px;

            input[type="file"] {
                position: absolute;
                width: 0;
                height: 0;
                padding: 0;
                overflow: hidden;
                border: 0;
            }

            .upload_name {
                color: var(--gray-scale-black);
                z-index: 1;
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: calc(100% - 30px);
            }

            label {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: inline-block;
                background-color: transparent;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%);
                    width: 24px;
                    height: 24px;
                }
            }
        }

        // number element (6 number)
        .number-element {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0 16px;
            width: 360px;
            max-width: 100%;
            height: 48px;
            background-color: #fff;
            transition: border-color 0.3s;
            overflow: hidden;
            padding: 0;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                font-size: 14px;
                font-weight: 400;
                color: transparent;
            }

            input::placeholder {
                color: var(--gray-scale-gray-4);
            }

            input:focus {
                outline: none;
            }

            .authKeyItems {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                height: 100%;
                border-bottom: 2px solid var(--gray-scale-gray-3);
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--gray-scale-black);
            }

            &:focus-within {
                border-color: var(--primary-primary);
            }
        }

        // buttons with input
        .button-element {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;

            .btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 44px;
                border-radius: 4px;
                font-size: 15px;
                font-weight: 700;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                padding: 0;
                cursor: pointer;

                &[disabled] {
                    background-color: #d6d7da;
                    border: 1px solid #d6d7da;
                    color: #666;
                    pointer-events: none;
                }

                &.btn-primary {
                    background-color: var(--primary-primary);
                    border: 1px solid var(--primary-primary);
                    color: #000;
                }

                &.btn-secondary {
                    background-color: var(--gray-scale-white);
                    border: 1px solid #d6d6d6;
                    color: #000;
                }

                &.btnSelectImage {
                    margin-left: 0;
                    width: 100%;

                    span {
                        color: var(--primary-primary);
                    }
                }

                &.btnFull {
                    width: 100%;
                    margin-left: 0;
                }

                &.primaryFill {
                    color: var(--gray-scale-white);
                }
            }
        }

        &:not(:first-child) {
            // margin-top: 8px;
        }

        // disabled
        &.disabled .input-element {
            border-color: var(--gray-scale-gray-3);
            background-color: var(--gray-scale-gray-2);

            input {
                color: var(--gray-scale-gray-4);
                pointer-events: none;
                cursor: auto;
            }
        }

        &.disabled .textarea-element {
            border-color: var(--gray-scale-gray-3);
            background-color: var(--gray-scale-gray-2);

            textarea {
                color: var(--gray-scale-gray-4);
                pointer-events: none;
                cursor: auto;
            }
        }

        &.disabled .select-element {
            border-color: var(--gray-scale-gray-3);
            background-color: var(--gray-scale-gray-2);

            .custom-select {
                color: var(--gray-scale-gray-4);
                pointer-events: none;
                cursor: auto;
            }

            .custom-select__control {
                background-color: inherit;
            }
        }

        // alert effects
        &.error .input-element {
            border-color: var(--alert);
        }

        &.success .input-element {
            border-color: var(--sub-color);
        }

        &+.alert-msg {
            display: none;
            font-size: 12px;
            font-weight: 400;
            margin-top: 4px;
        }

        &.error+.alert-msg.error {
            display: block;
            color: var(--alert);
        }

        &.success+.alert-msg.success {
            display: block;
            color: var(--sub-color);
        }

        &.timer+.alert-msg.timer {
            display: block;
            color: var(--gray-scale-gray-1);
        }

        // default textarea
        .textarea-element {
            display: flex;
            align-items: center;
            width: 360px;
            max-width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid var(--gray-scale-gray-3);
            transition: border-color 0.3s;
            overflow: hidden;
            padding: 10px 12px;

            textarea {
                width: 100%;
                height: 60px;
                max-height: 100%;
                background: transparent;
                border: none;
                font-family: var(--font-family);
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--gray-scale-gray-7);
                resize: none;
                white-space: pre-wrap;
            }

            textarea::placeholder {
                font-family: var(--font-family);
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--gray-scale-gray-4);
            }

            textarea:focus {
                outline: none;
            }

            &:focus-within {
                border-color: var(--primary-primary);
            }
        }

        // tags
        .tag-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            width: 100%;

            .tag {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 44px;
                background-color: #cdcdcd;
                border-radius: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #fefefe;
                cursor: pointer;

                &.active {
                    background-color: #ff9a9a;
                }
            }
        }
    }

    .form-checkbox {
        display: flex;
        margin-top: 8px;

        .checkbox {
            label {
                span {
                    margin-left: 8px;
                }
            }
        }
    }

    // auth info & buttons
    .auth-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 17px;

        .remain_time {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-1);
        }

        button {
            padding: 4px 16px;
            border: 1px solid var(--gray-scale-gray-4);
            background-color: #fff;
            border-radius: 6px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--gray-scale-gray-1);
        }
    }

    // certification button
    .btn-certification {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 64px;
        height: 28px;
        background-color: var(--gray-scale-gray-3);
        border: 1px solid var(--gray-scale-gray-3);
        border-radius: 4px;
        padding: 0;
        font-size: 12px;
        color: var(--gray-scale-gray-4);
        cursor: pointer;

        &.completed {
            background-color: var(--primary-primary);
            border-color: var(--primary-primary);
            color: var(--gray-scale-black);
        }

        &.certified {
            background-color: var(--gray-scale-white);
            border-color: var(--primary-primary);
            color: var(--gray-scale-black);
        }
    }

    // resident registration number input
    &.devide-2-dash {
        .form-field {
            position: relative;
            gap: 20px;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 5px;
                height: 1px;
                background-color: var(--gray-scale-gray-4);
            }
        }
    }

    // file image
    .formImage {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        // overflow-x: scroll;
        margin-top: 20px;
        padding-bottom: 4px;

        .imgItem {
            position: relative;
            width: 102px;
            height: 102px;
            flex-grow: 0;
            flex-shrink: 0;
            opacity: 0.8;
            border-radius: 4px;
            background-color: #d9d9d9;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .btnRemoveImg {
                content: "";
                position: absolute;
                top: 6px;
                right: 6px;
                width: 16px;
                height: 16px;
                background: url("/resources/icon/icon-remove_white.svg") center center no-repeat;
                background-size: cover;
            }
        }

        .image-empty {
            position: relative;
            background-color: #e8e8e8;
            width: 188px;
            height: 188px;
            border-radius: 12px;
            border: 1px solid #e8e8e8;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
                width: 24px;
                height: 24px;
                // background: url("../../resources/icons/icon-camera.svg");
            }
        }
    }
}

// Radio button
.input-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    .radiobox {
        position: relative;

        input[type="radio"] {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }

        input[type="radio"]+label {
            display: inline-block;
            position: relative;
            padding-left: 26px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            font-size: 15px;
            font-weight: 500;
            color: #7b7b7b;
        }

        input[type="radio"]:checked+label {
            color: #000;
        }

        input[type="radio"]+label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            text-align: center;
            background-color: #fff;
            border: 1px solid #d9d9d9;
            border-radius: 100%;
            box-sizing: border-box;
        }

        input[type="radio"]+label:before,
        input[type="radio"]+label:after {
            transition: border-color 0.35s, background-color 0.35s;
        }

        input[type="radio"]+label:active:before,
        input[type="radio"]:checked+label:active:before {}

        input[type="radio"]:checked+label:before {
            background-color: #fff;
            border-color: #fcc02f;
        }

        input[type="radio"]+label:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 4px;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background-color: transparent;
            border-radius: 50%;
        }

        input[type="radio"]:checked+label:after {
            background-color: #fcc02f;
        }
    }
}

// Checkbox
.checkboxes {
    display: flex;
    gap: 12px;
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        input {
            display: none;
        }

        div {
            flex-shrink: 0;
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url("/resources/icon/checkbox.svg") center center / cover no-repeat;
            cursor: pointer;
        }

        span {
            display: inline-block;
            margin-left: 10px;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        input:checked~div {
            width: 16px;
            height: 16px;
            background: url("/resources/icon/checkbox_active.svg") center center / cover no-repeat;
        }
    }
}

// range
input[type="range"] {
    overflow: hidden;
    height: 30px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background: transparent;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid #ff96ab;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: #ff494f;
    box-shadow: 1px 1px 7px #d16a6e;
    cursor: pointer;
    box-shadow: -100vw 0 0 100vw #ff96ab;
}

// select
.select-element {
    display: flex;
    align-items: center;
    width: 360px;
    max-width: 100%;
    height: 44px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid var(--gray-scale-gray-3);
    transition: border-color 0.3s;
    // overflow: hidden;
    padding: 0 8px;

    &:focus-within {
        border-color: var(--primary-primary);
    }

    select {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-scale-black);
        outline: none;
        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::-ms-expand {
            display: none;
        }

        background: transparent url("/resources/icon/select-marker.svg") calc(100%) center no-repeat;
        background-size: 20px;
        padding: 0;

        option {}
    }
}

// datepicker
.input-datepicker {
    position: relative;
    display: inline-flex;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0 8px 0 16px;
    border-radius: 5px;
    background-color: var(--gray-scale-white);
    transition: border-color 0.3s;

    &:focus-within {
        border-color: var(--primary-primary);
    }

    input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal7;
        letter-spacing: normal;
        text-align: left;
        color: var(--gray-scale-black);

        &:focus {
            outline: none;
        }
    }

    &::after {
        display: none;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        content: "";
        width: 24px;
        height: 24px;
        background: url("/resources/icon/calander.svg") center center no-repeat;
        background-size: cover;
        cursor: pointer;
    }
}

// table-datepicker
.table-datepicker {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: var(--gray-scale-white);
    transition: border-color 0.3s;

    &:focus-within {
        border-color: var(--primary-primary);
    }

    input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal7;
        letter-spacing: normal;
        text-align: left;
        color: var(--gray-scale-black);

        &:focus {
            outline: none;
        }
    }

    &::after {
        display: none;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        content: "";
        width: 24px;
        height: 24px;
        background: url("/resources/icon/calander.svg") center center no-repeat;
        background-size: cover;
        cursor: pointer;
    }
}

.alert-period {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 15px;

    .input-datepicker {
        width: 200px;
        height: 35px;
        padding: 0 16px;
        border: 1px solid #c5cde0;
        border-radius: 0;
    }
}

// search
.formContainer {
    width: 100%;

    .formHeader {
        margin: 20px 0 40px 0;

        p {
            margin-top: 8px;
        }
    }

    .formResult {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        width: 100%;
        height: 100%;

        p {}
    }

    .subTabHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .btnTab {
            flex: 1;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px solid transparent;
            padding: 12px 0;
            outline: none;

            >span {
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--gray-scale-gray-4);
            }

            &.active {
                border-color: var(--primary-primary);

                >span {
                    color: var(--primary-primary);
                }
            }
        }
    }

    .formOptions {
        display: flex;
        align-items: center;
        gap: 18px;

        .options {
            position: relative;

            .btnMore {}

            .optionsDetails {
                position: absolute;
                top: 100%;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 91px;
                gap: 12px;
                padding: 12px 14px;
                border-radius: 5px;
                border: solid 1px var(--gray-scale-gray-3);
                background-color: var(--gray-scale-white);

                >button {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--primary-primary);
                }
            }
        }
    }

    >h5 {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--gray-scale-gray-7);
        margin-bottom: 20px;
    }

    .inputSearch {
        width: 100%;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        border: solid 1px var(--gray-scale-gray-3);
        transition: 0.35s;
        will-change: border;

        .textEsset {
            flex-shrink: 0;
            cursor: pointer;

            >img {}
        }

        >input {
            width: 100%;
            height: 100%;
            padding-left: 12px;
        }

        &:focus-within {
            border: solid 1px var(--primary-primary);
        }
    }
}

// form tab
.formTabWrapper {
    margin-bottom: 20px;

    .tabHeader {
        display: flex;
        gap: 10px;
        overflow-x: scroll;
        margin-right: -6px;
        padding-right: 8px;

        &::-webkit-scrollbar {
            display: none;
            height: 4px;
        }

        >button {
            min-width: 111px;
            height: 58px;
            flex-grow: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 12px 0 6px 0;
            background-color: var(--none);
            border: solid var(--none);
            border-width: 0 0 3px 0;

            >span {
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--gray-scale-gray-4);
            }

            &.tabActive {
                border-color: var(--primary-primary);

                >span {
                    color: var(--primary-primary);
                }
            }
        }
    }
}

// added driver
.addedFormWrapper {
    display: flex;
    flex-direction: column;

    .addedInfoBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        // gap: 20px;
        padding: 16px;
        margin-bottom: 20px;
        border-radius: 5px;
        background-color: var(--gray-scale-gray-1);

        .btnRemove {
            position: absolute;
            top: 16px;
            right: 16px;
        }

        .Body1Semibold {
            margin-bottom: 20px;
        }
    }
}

.form-summary {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .txt-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tit {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-7);

            .info {
                position: relative;

                >i {
                    position: relative;
                    top: -1px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    border: 1px solid var(--gray-scale-gray-6);
                    border-radius: 50%;
                    font-size: 12px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--gray-scale-gray-6);
                    cursor: help;
                }

                >span {
                    position: absolute;
                    top: -12px;
                    left: 100%;
                    display: inline-block;
                    background-color: var(--gray-scale-gray-2);
                    padding: 2px 4px;
                    border: 1px solid var(--gray-scale-gray-3);
                    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.06);
                    border-radius: 5px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.35s;
                    will-change: opacity, visibility;
                    font-size: 13px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--gray-scale-gray-6);
                    white-space: nowrap;
                }

                &:hover {
                    >span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .desc {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: var(--gray-scale-gray-7);

            b {
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: var(--gray-scale-gray-7);
            }
        }
    }

    .txt-vertical {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
        margin-top: 12px;

        .tit {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-7);
        }

        .desc {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: var(--gray-scale-gray-7);

            .checkList {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                gap: 10px 0;

                .checkbox {
                    width: 50%;
                    justify-content: flex-start;

                    >label {
                        gap: 12px;
                    }
                }
            }

            .radioList {
                display: flex;
                align-items: center;
                width: 100%;

                .input-radio {
                    .radiobox {
                        width: 100%;
                        max-width: unset;
                        text-align: left;
                    }
                }
            }
        }
    }
}

.form-wrapper {
    position: relative;
    width: 100%;
    max-width: 1380px;

    // form Header
    .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .form-title {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                font-size: 24px;
                font-weight: 700;
                color: #333;
            }

            .sub-desc {
                font-size: 14px;
                font-weight: 500;
                color: #333;
            }
        }
    }

    .form-body {
        margin-top: 30px;

        .fb-title {
            .tit {
                font-size: 12px;
                font-weight: 500;
                color: #8f9bb3;
            }

            .input-tit {
                margin-top: 2px;

                input {
                    width: 100%;
                    max-width: 900px;
                    height: 35px;
                    border: 1px solid #c5cde0;
                    outline: none;
                    padding: 0 16px;
                    background-color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;
                }
            }

            .input-file {
                margin-top: 15px;
                display: flex;
                align-items: center;
                gap: 5px;

                .btn-file {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 120px;
                    height: 35px;
                    background-color: #fff;
                    border: 1px solid #718096;
                }

                .filename {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    max-width: 775px;
                    height: 35px;
                    border: 1px solid #e4e9f2;
                    background-color: #f7f9fc;
                    padding: 0 12px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333;

                    span {
                        display: inline-flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;

                        a {
                            display: inline-block;
                            font-size: 14px;
                            color: #333;
                            cursor: pointer;
                        }

                        button {
                            display: inline-flex;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .fb-textarea {
            position: relative;
            width: 100%;
            max-width: 1380px;
            height: 600px;
            margin-top: 10px;
            background-color: #fff;
        }

        .form-body-tit {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #333;
        }

        .alert-condition {
            display: flex;
            justify-content: flex-start;
            gap: 100px;
            margin-bottom: 73px;

            .item {
                .item-tit {
                    font-size: 12px;
                    font-weight: 500;
                    color: #8f9bb3;
                }

                .item-desc {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: 5px;

                    .btn-alert_condition_true {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 95px;
                        height: 30px;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: #ff9a9a;
                        color: #fefefe;
                    }

                    .btn-alert_condition_false {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 95px;
                        height: 30px;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: #d6d7da;
                        color: #6a6a6a;
                    }

                    .btn-alert_condition {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 95px;
                        height: 30px;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 500;

                        &:nth-child(1) {
                            background-color: #ff9a9a;
                            color: #fefefe;
                        }

                        &:nth-child(2) {
                            background-color: #d6d7da;
                            color: #6a6a6a;
                        }
                    }
                }
            }
        }

        .alert-content {
            .alert-content-tit {
                display: block;
                font-size: 12px;
                font-weight: 500;
                color: #8f9bb3;
            }

            textarea {
                width: 100%;
                max-width: 900px;
                height: 150px;
                margin-top: 2px;
                background-color: #fff;
                border: 1px solid #c5cde0;
                outline: none;
                resize: none;
                padding: 10px;
            }
        }

        .form-input_list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px 20px;
            width: 100%;
            max-width: 790px;
            margin-bottom: 10px;

            .form-input-item {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: calc(100% / 3);

                >label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #8f9bb3;
                }

                >input {
                    width: 100%;
                    height: 35px;
                    border: 1px solid #c5cde0;
                    font-size: 12px;
                    outline: none;
                    color: #565656;
                    padding: 10px;
                }
            }
        }
    }

    .form-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        margin-top: 30px;

        .btn-form {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 95px;
            height: 30px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;

            &.btn-delete {
                background-color: #e4ecf1;
                color: #6a6a6a;
            }

            &.btn-save {
                background-color: #ff5454;
                color: #fefefe;
            }
        }
    }
}

// pagination
.pagination {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    .numList {
        position: relative;
        display: flex;
        align-items: center;

        .btnPrev,
        .btnNext {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            // background-color: var(--primary-primary);
            cursor: pointer;
            font-size: 18px;
            color: #000;

            &[aria-current="false"] {
                opacity: 0.2;
                pointer-events: none;
            }
        }

        .btnPrev {
            left: -42px;
        }

        .btnNext {
            right: -42px;
        }

        .currentPage {
            display: flex;
            align-items: center;
            gap: 0 4px;

            li {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: var(--gray-scale-gray-5);
                cursor: pointer;

                &.active {
                    color: #000;
                    background-color: var(--primary-primary);
                }
            }
        }

        .lastPage {
            position: relative;
            margin-left: 15px;
            padding-left: 30px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;

            &::before {
                content: "...";
                position: absolute;
                bottom: 0;
                left: 0;
                cursor: default;
            }
        }
    }
}