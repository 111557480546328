// Table
.table-wrapper {
  position: relative;
  width: 100%;
  max-width: 1380px;

  &.size-800 {
    max-width: 800px;
  }

  // Table Header
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .input-search {
      position: relative;
      width: 270px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #cdcdcd;
      padding: 0 10px;

      input {
        font-size: 12px;
        font-weight: 400;
      }

      button {
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
      }
    }

    .table-title {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      .title {
        font-size: 24px;
        font-weight: 700;
        color: #333;
      }

      .sub-desc {
        font-size: 14px;
        font-weight: 500;
        color: #626262;

        em {
          font-weight: 700;
          color: #333;
        }
      }
    }

    // Search Options
    .search-option {
      display: flex;
      align-items: center;
      gap: 10px;

      .select-filter {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 170px;
        height: 30px;
        padding: 0 10px;
        background-color: #fff;
        border: solid 1px #cdcdcd;
        transition: 0.35s;
        will-change: border;

        @media (max-width: 767px) {
          display: none;
        }

        > select {
          min-width: 58px;
          border: none;
          outline: none;
          font-size: 12px;
          text-align: right;
          color: var(--gray-scale-black);

          > option {
            text-align: center;
          }
        }

        &:focus-within {
          border: solid 1px var(--primary-primary);
        }
      }

      .input-search {
        width: 200px;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 14px;
        border-radius: 4px;
        background-color: #fff;
        border: solid 1px #cdcdcd;
        transition: 0.35s;
        will-change: border;

        .text-esset {
          display: inline-flex;
          align-items: center;
          flex-shrink: 0;

          &.btn-search {
            cursor: pointer;
          }

          > img {
          }
        }

        > input {
          width: 100%;
          height: 100%;
          font-size: 12px;
          font-weight: 500;
        }

        &:focus-within {
          border: solid 1px var(--primary-primary);
        }
      }

      .buttonExcel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        height: 30px;
        padding: 0 12px;
        background-color: #fefefe;
        border-radius: 4px;
        border: solid 1px #cdcdcd;
        font-size: 12px;
        font-weight: 500;
        color: #565656;
      }

      .buttonEnrollCompany {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        height: 30px;
        padding: 0 12px;
        background-color: #ff5454;
        border-radius: 4px;
        border: solid 1px #ff5454;
        font-size: 12px;
        font-weight: 500;
        color: #fefefe;
      }

      .buttonReset {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 48px;
        padding: 0 12px;
        border-radius: 8px;
        border: solid 1px var(--gray-scale-gray-3);
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--gray-scale-black);
      }

      @media (max-width: 767px) {
        width: 100%;

        .selectFilter {
          display: none;
        }

        .inputSearch {
          width: calc(100%);
        }

        .buttonSearch {
          // display: none;
          min-width: 73px;
          padding: 0;
          justify-content: center;
        }

        .buttonReset {
          display: none;
        }
      }
    }

    // Table Options
    .tableOption {
      display: flex;
      align-items: center;
      gap: 10px;

      .optionList {
        display: flex;
        align-items: center;
        gap: 10px;

        .selectFilter {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 48px;
          padding: 12px 10px;
          border-radius: 8px;
          border: solid 1px var(--gray-scale-gray-3);
          transition: 0.35s;
          will-change: border;

          > select {
            min-width: 58px;
            border: none;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: var(--gray-scale-black);

            > option {
              text-align: center;
            }
          }

          &:focus-within {
            border: solid 1px var(--primary-primary);
          }
        }
      }

      @media (max-width: 767px) {
        display: block;
        width: 24px;
        height: 24px;
        background: url("/resources/icon/MoreBold.svg") center center no-repeat;
        background-size: cover;
        cursor: pointer;

        .optionList {
          display: none;

          .selectFilter {
            display: none;
          }
        }

        &.on {
          position: relative;

          .optionList {
            position: absolute;
            top: 30px;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            width: max-content;
            padding: 12px 14px;
            border-radius: 8px;
            border: solid 1px var(--gray-scale-gray-3);
            background-color: var(--gray-scale-white);
            z-index: 5;

            > button {
              min-width: auto;
              height: auto;
              padding: 0;
              border: none;
              border-radius: 0;

              &.btnMain {
                font-size: 14px;
              }

              &.primaryOutline {
              }

              &.grayOutline {
              }
            }
          }
        }
      }
    }

    // Data Info
    .dataInfo {
      display: flex;
      align-items: center;
      gap: 20px;

      .pageBack {
        > button {
          > img {
          }
        }
      }

      .dataTitle {
        .carName {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--gray-scale-gray-7);
        }
      }
    }

    @media (max-width: 767px) {
      gap: 12px;

      .dataInfo {
        gap: 10px;

        .dataTitle {
          .carName {
            font-size: 18px;
          }
        }
      }
    }
  }

  // Inner Tab (청구/수납)
  .tableInnerTab {
    display: flex;
    align-items: center;
    gap: 12px;

    .btnTab {
      min-width: 116px;
      height: 48px;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 0 20px;
      border-radius: 8px;
      cursor: auto;

      span {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-scale-white);
      }

      &:nth-child(1) {
        border: solid 1px var(--primary-primary);
        background-color: var(--primary-primary);
      }

      &:nth-child(2) {
        border: solid 1px var(--sub-color);
        background-color: var(--sub-color);
      }

      &:nth-child(3) {
        border: solid 1px var(--subcolor);
        background-color: var(--subcolor);
      }
    }

    @media (max-width: 767px) {
      .btnTab {
        flex: 1;
        min-width: auto;
        padding: 0;
      }
    }
  }

  // Table Tab
  .tableTab {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 40px);
      height: 1px;
      background-color: var(--gray-scale-gray-3);
      margin: 0 -20px 0 -20px;
    }

    .tabButtons {
      display: flex;

      > button {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        padding: 22px 22px 12px 22px;
        background-color: var(--none);
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-scale-gray-4);
        border-bottom: 3px solid transparent;

        &.active {
          border-color: var(--primary-primary);
          color: var(--primary-primary);
        }
      }

      // Search Options
      .searchOption {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;

        .selectFilter {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 48px;
          padding: 12px 10px;
          border-radius: 8px;
          border: solid 1px var(--gray-scale-gray-3);
          transition: 0.35s;
          will-change: border;

          @media (max-width: 767px) {
            display: none;
          }

          > select {
            min-width: 58px;
            border: none;
            outline: none;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: var(--gray-scale-black);

            > option {
              text-align: center;
            }
          }

          &:focus-within {
            border: solid 1px var(--primary-primary);
          }
        }

        .inputSearch {
          width: 254px;
          height: 48px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0 14px;
          border-radius: 8px;
          border: solid 1px var(--gray-scale-gray-3);
          transition: 0.35s;
          will-change: border;

          .textEsset {
            flex-shrink: 0;

            &.btnSearch {
              cursor: pointer;
            }

            > img {
            }
          }

          > input {
            width: 100%;
            height: 100%;
            padding-left: 12px;
          }

          &:focus-within {
            border: solid 1px var(--primary-primary);
          }
        }

        @media (max-width: 767px) {
          width: 100%;

          .inputSearch {
            width: 100%;
          }
        }

        .buttonSearch {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 48px;
          padding: 0 12px;
          border-radius: 8px;
          border: solid 1px var(--gray-scale-gray-3);
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: var(--gray-scale-black);
        }
      }
    }
  }

  // Table Contents
  .table-content {
    min-height: 714px;

    .table-option {
      display: flex;
      gap: 10px;
      width: 100%;
      height: 60px;
      background-color: #e4ecf1;
      border: solid #cdcdcd;
      border-width: 1px 1px 0 1px;
      padding: 12px 24px;

      .option-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .item-tit {
          font-size: 14px;
          font-weight: 500;
          color: #626262;
        }

        .item-desc {
          display: flex;
          align-items: center;
          gap: 2px;

          .input-element {
            width: 80px;
            height: 35px;
            background-color: #fff;
            border-radius: 4px;
            overflow: hidden;

            input {
              width: 100%;
              height: 100%;
              padding: 0 8px;
              font-size: 12px;
            }
          }

          .select-element {
            width: 100px;
            height: 35px;
            background-color: #fff;
            border-radius: 4px;
            border: none;

            * {
              font-size: 12px;
            }
          }

          .input-datepicker {
            width: 100px;
            border: none;
            border-radius: 4px;
          }
        }

        .btn-search {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 35px;
          background-color: #ff5454;
          border-radius: 0;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          margin-left: 35px;
        }
      }
    }

    .table-body {
      position: relative;
      width: 100%;

      .btn-scroll {
        position: absolute;
        top: 20px;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.15s;
        z-index: 5;

        &.scroll-left {
          left: 12px;
          transform: rotate(180deg);
        }
        &.scroll-right {
          right: 12px;
        }
        &.hidden {
          opacity: 0;
        }
      }
      &.table-row-scroll {
        overflow-x: auto;
        border: solid #e0dede;
        border-width: 0 1px 1px 1px;
        background-color: #f8f9fd;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
          background: none;
        }

        table {
          border: none;
          transition: transform 0.5s;

          thead {
            border-bottom: none;
          }
        }
      }
    }

    // Table
    table {
      width: 100%;
      border: solid #e0dede;
      border-width: 0 1px 0 1px;
      table-layout: fixed;

      tr {
        border: solid #e0dede;
        border-width: 1px 0 0 0;

        &:last-child {
          border-width: 1px 0 1px 0;
        }
      }

      th,
      td {
        min-width: 34px;
        border: solid #e0dede;
        border-width: 1px 0;
        vertical-align: middle;

        input[type="checkbox"] {
          cursor: pointer;
        }

        &[data-align="left"] {
          text-align: left;
          padding: 10px 12px;
        }

        &[data-align="center"] {
          text-align: center;
        }

        span {
          display: inline-flex;
          align-items: center;
          min-height: 34px;
        }
      }

      thead {
        border-bottom: 1px solid #e0dede;

        tr {
          th {
            background-color: #e4ecf1;
            border-width: 1px;

            span {
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #626262;

              &.cellOH {
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                padding-left: 8px;
                padding-right: 8px;
                transition: height 0.35s;

                &:hover {
                  white-space: normal;
                }
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            background-color: #fff;
            border-bottom: 1px solid #e0dede;

            &.align-center {
              text-align: center;
            }

            .cellTit {
              display: none;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: var(--gray-scale-black);
            }

            span {
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #565656;
              line-height: normal;

              &.cellOH {
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                padding-left: 8px;
                padding-right: 8px;
              }

              &.cellOH2 {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 8px;
                padding-right: 8px;
                text-align: center;

                &:hover {
                  display: inline-block;
                  overflow: visible;
                  text-overflow: unset;
                }
              }

              .summaries {
                display: none;

                .cellSummary {
                }
              }
            }

            .td-button-list {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;

              .td-button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                min-width: 60px;
                min-height: 22px;
                border-radius: 4px;
                background-color: rgba(89, 89, 89, 0.4);
                font-size: 12px;
                font-weight: 700;
                color: #fff;

                &.bg-color-red {
                  background-color: #ff9a9a;
                }
              }
            }

            .moreDetails {
              display: none;
            }
          }
        }
      }

      &.thead-type-2 {
        thead {
          tr {
            th {
              background-color: #f8f9fd;
              border-width: 0;
              height: 60px;

              span {
                font-size: 12px;
                color: #565656;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              span {
                font-size: 12px;
              }
            }
          }
        }
      }

      // 가로 유지 모바일 테이블
      &.steady {
        width: 100%;

        th,
        td {
          &[data-mobile-view="show"] {
            width: auto !important;
          }

          &[data-mobile-view="hide"] {
            display: none;
          }

          &:nth-child(1) {
            width: 34px !important;
          }
        }

        thead {
          width: 100%;

          tr {
            width: 100%;

            th {
            }
          }
        }

        tbody {
          tr {
            td {
            }
          }
        }
      }

      .moreButton {
        display: none;
      }

      @media (max-width: 767px) {
        // 모바일 최적화 테이블 (컬럼형)
        &.mobile {
          td {
          }

          thead {
            border: none;

            tr {
              th {
                display: none;
              }
            }
          }

          tbody {
            tr {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-end;
              justify-content: flex-end;
              // flex-direction: column;
              // padding: 45px 12px 12px 45px;
              padding: 12px;
              border-width: 0 0 1px 0;

              td {
                position: relative;
                display: flex;
                align-items: flex-start;
                border: none;
                padding: 0;
                min-height: auto;

                .cellTit {
                  display: block;
                  width: 100px;
                  flex-shrink: 0;
                }

                .cell {
                  justify-content: flex-start;
                  text-align: left;
                  font-size: 12px;
                  line-height: normal;
                  text-align: left;
                  color: var(--gray-scale-black);

                  .pcCell {
                    display: none;
                  }

                  .summaries {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 15px;
                    margin-left: 15px;

                    .cellSummary {
                    }
                  }

                  button {
                    width: 100%;
                  }
                }

                &[data-mobile-view="show"] {
                  width: 100%;
                  // width: calc(100% - 42px);
                  background-color: var(--gray-scale-gray-1);
                  padding: 4px 20px;

                  &:nth-of-type(2) {
                    margin-top: 12px;
                    padding-top: 12px;
                  }
                }

                &[data-type="summaries"] {
                  width: 100%;
                  background-color: #fff;
                }

                &[data-type="button-1"] {
                  padding-bottom: 12px;

                  > span {
                    width: 100%;
                  }
                }

                &[data-type="button-2"] {
                  width: calc(50%);
                  // width: calc(50% - 21px);
                  padding: 4px 20px 12px 20px;

                  &:nth-of-type(1) {
                    background-color: red;
                  }

                  > span {
                    width: 100%;
                  }
                }

                &:nth-child(n + 2) {
                  display: none;
                }

                .moreDetails {
                  display: block;
                  position: absolute;
                  top: 50%;
                  right: 0px;
                  transform: translateY(-50%) rotate(180deg);
                }

                .moreButton {
                  display: block;
                  position: absolute;
                  top: 50%;
                  right: 0px;
                  transform: translateY(-50%);
                }
              }

              &:last-child {
                border-width: 0 0 1px 0;
              }

              &.open {
                td {
                  &:nth-child(n + 2) {
                    display: flex;

                    &[data-mobile-view="show"] {
                      display: flex;
                    }

                    &[data-mobile-view="hide"] {
                      display: none;
                    }
                  }
                }

                .moreDetails {
                  transform: translateY(-50%) rotate(0deg);
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      min-height: calc(100vh - 366px);
    }
  }

  // Table Footer
  .table-footer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 30px 0 0;

    .buttonExcel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      height: 30px;
      padding: 0 12px;
      background-color: #fefefe;
      border-radius: 8px;
      border: solid 1px #cdcdcd;
      font-size: 12px;
      font-weight: 500;
      color: #565656;
    }

    .select-filter {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 150px;
      height: 30px;
      padding: 0 10px;
      border-radius: 8px;
      background-color: #fff;
      border: solid 1px var(--gray-scale-gray-3);
      transition: 0.35s;
      will-change: border;

      @media (max-width: 767px) {
        display: none;
      }

      > select {
        min-width: 58px;
        border: none;
        outline: none;
        font-size: 12px;
        font-weight: 500;
        text-align: right;
        color: #565656;

        > option {
          text-align: center;
        }
      }

      &:focus-within {
        border: solid 1px var(--primary-primary);
      }
    }

    .pageGuide {
      .curStats {
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: var(--gray-scale-gray-4);
      }
    }

    .pagination {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      gap: 8px;

      .pagiPrev {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        > img {
        }
      }

      .pagiNext {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        > img {
        }
      }

      > ul {
        display: flex;
        align-items: center;
        gap: 10px;

        > li {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          width: 30px;
          height: 30px;
          cursor: pointer;

          > span {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: var(--gray-scale-gray-4);
          }

          &.active {
            background-color: #fb3b3b;

            span {
              color: #fff;
            }
          }

          &.disabled {
            span {
              color: var(--gray-scale-gray-4);
            }
          }
        }
      }

      .lastPage {
        position: relative;
        padding-left: 15px;

        &::before {
          position: absolute;
          content: "...";
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--gray-scale-gray-4);
        }

        li {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--gray-scale-gray-4);
          cursor: pointer;
        }
      }
    }

    @media (max-width: 767px) {
      justify-content: center;

      .pageGuide {
        display: none;
      }
    }
  }
}

.table-summary {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .txt-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tit {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--gray-scale-gray-7);
    }

    .desc {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: var(--gray-scale-gray-7);

      b {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: var(--primary-primary);
      }
    }
  }
}

[data-mobile-view="1"] {
  width: 1% !important;
}

[data-mobile-view="2"] {
  width: 2% !important;
}

[data-mobile-view="3"] {
  width: 3% !important;
}

[data-mobile-view="4"] {
  width: 4% !important;
}

[data-mobile-view="5"] {
  width: 5% !important;
}

[data-mobile-view="6"] {
  width: 6% !important;
}

[data-mobile-view="7"] {
  width: 7% !important;
}

[data-mobile-view="8"] {
  width: 8% !important;
}

[data-mobile-view="9"] {
  width: 9% !important;
}

[data-mobile-view="10"] {
  width: 10% !important;
}

[data-mobile-view="11"] {
  width: 11% !important;
}

[data-mobile-view="12"] {
  width: 12% !important;
}

[data-mobile-view="13"] {
  width: 13% !important;
}

[data-mobile-view="14"] {
  width: 14% !important;
}

[data-mobile-view="15"] {
  width: 15% !important;
}

[data-mobile-view="16"] {
  width: 16% !important;
}

[data-mobile-view="17"] {
  width: 17% !important;
}

[data-mobile-view="18"] {
  width: 18% !important;
}

[data-mobile-view="19"] {
  width: 19% !important;
}

[data-mobile-view="20"] {
  width: 20% !important;
}

[data-mobile-view="21"] {
  width: 21% !important;
}

[data-mobile-view="22"] {
  width: 22% !important;
}

[data-mobile-view="23"] {
  width: 23% !important;
}

[data-mobile-view="24"] {
  width: 24% !important;
}

[data-mobile-view="25"] {
  width: 25% !important;
}

[data-mobile-view="26"] {
  width: 26% !important;
}

[data-mobile-view="27"] {
  width: 27% !important;
}

[data-mobile-view="28"] {
  width: 28% !important;
}

[data-mobile-view="29"] {
  width: 29% !important;
}

[data-mobile-view="30"] {
  width: 30% !important;
}

[data-mobile-view="31"] {
  width: 31% !important;
}

[data-mobile-view="32"] {
  width: 32% !important;
}

[data-mobile-view="33"] {
  width: 33% !important;
}

[data-mobile-view="34"] {
  width: 34% !important;
}

[data-mobile-view="35"] {
  width: 35% !important;
}

[data-mobile-view="36"] {
  width: 36% !important;
}

[data-mobile-view="37"] {
  width: 37% !important;
}

[data-mobile-view="38"] {
  width: 38% !important;
}

[data-mobile-view="39"] {
  width: 39% !important;
}

[data-mobile-view="40"] {
  width: 40% !important;
}

[data-mobile-view="41"] {
  width: 41% !important;
}

[data-mobile-view="42"] {
  width: 42% !important;
}

[data-mobile-view="43"] {
  width: 43% !important;
}

[data-mobile-view="44"] {
  width: 44% !important;
}

[data-mobile-view="45"] {
  width: 45% !important;
}

[data-mobile-view="46"] {
  width: 46% !important;
}

[data-mobile-view="47"] {
  width: 47% !important;
}

[data-mobile-view="48"] {
  width: 48% !important;
}

[data-mobile-view="49"] {
  width: 49% !important;
}

[data-mobile-view="50"] {
  width: 50% !important;
}

[data-mobile-view="51"] {
  width: 51% !important;
}

[data-mobile-view="52"] {
  width: 52% !important;
}

[data-mobile-view="53"] {
  width: 53% !important;
}

[data-mobile-view="54"] {
  width: 54% !important;
}

[data-mobile-view="55"] {
  width: 55% !important;
}

[data-mobile-view="56"] {
  width: 56% !important;
}

[data-mobile-view="57"] {
  width: 57% !important;
}

[data-mobile-view="58"] {
  width: 58% !important;
}

[data-mobile-view="59"] {
  width: 59% !important;
}

[data-mobile-view="60"] {
  width: 60% !important;
}

[data-mobile-view="61"] {
  width: 61% !important;
}

[data-mobile-view="62"] {
  width: 62% !important;
}

[data-mobile-view="63"] {
  width: 63% !important;
}

[data-mobile-view="64"] {
  width: 64% !important;
}

[data-mobile-view="65"] {
  width: 65% !important;
}

[data-mobile-view="66"] {
  width: 66% !important;
}

[data-mobile-view="67"] {
  width: 67% !important;
}

[data-mobile-view="68"] {
  width: 68% !important;
}

[data-mobile-view="69"] {
  width: 69% !important;
}

[data-mobile-view="70"] {
  width: 70% !important;
}

[data-mobile-view="71"] {
  width: 71% !important;
}

[data-mobile-view="72"] {
  width: 72% !important;
}

[data-mobile-view="73"] {
  width: 73% !important;
}

[data-mobile-view="74"] {
  width: 74% !important;
}

[data-mobile-view="75"] {
  width: 75% !important;
}

[data-mobile-view="76"] {
  width: 76% !important;
}

[data-mobile-view="77"] {
  width: 77% !important;
}

[data-mobile-view="78"] {
  width: 78% !important;
}

[data-mobile-view="79"] {
  width: 79% !important;
}

[data-mobile-view="80"] {
  width: 80% !important;
}

[data-mobile-view="81"] {
  width: 81% !important;
}

[data-mobile-view="82"] {
  width: 82% !important;
}

[data-mobile-view="83"] {
  width: 83% !important;
}

[data-mobile-view="84"] {
  width: 84% !important;
}

[data-mobile-view="85"] {
  width: 85% !important;
}

[data-mobile-view="86"] {
  width: 86% !important;
}

[data-mobile-view="87"] {
  width: 87% !important;
}

[data-mobile-view="88"] {
  width: 88% !important;
}

[data-mobile-view="89"] {
  width: 89% !important;
}

[data-mobile-view="90"] {
  width: 90% !important;
}

[data-mobile-view="91"] {
  width: 91% !important;
}

[data-mobile-view="92"] {
  width: 92% !important;
}

[data-mobile-view="93"] {
  width: 93% !important;
}

[data-mobile-view="94"] {
  width: 94% !important;
}

[data-mobile-view="95"] {
  width: 95% !important;
}

[data-mobile-view="96"] {
  width: 96% !important;
}

[data-mobile-view="97"] {
  width: 97% !important;
}

[data-mobile-view="98"] {
  width: 98% !important;
}

[data-mobile-view="99"] {
  width: 99% !important;
}

[data-mobile-view="100"] {
  width: 100% !important;
}
