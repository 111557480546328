// Main-Color
$color-primary: #ffc108;
$color-secondary: #2c4762;
$color-third: #ff7a3b;
// System-Color
$color-susscess: #6cd15a;
$color-danger: #e64841;
// Grayscales
$color-black: #000000;
$color-gray1: #3d3d3d;
$color-gray2: #666666;
$color-gray3: #808080;
$color-gray4: #9f9f9f;
$color-gray5: #bfbfbf;
$color-gray6: #eaeaea;
$color-gray7: #f6f6f6;
$color-white: #ffffff;
// Text Badge
.caseText {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 0.5px 4.8px;
    border-radius: 12px;
    background-color: var(--primary-primary);
    flex-grow: 0;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--gray-scale-white);
}

.Property-1Default {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 10px;
    background-color: var(--none);

    span {
        width: 7px;
        height: 24px;
        flex-grow: 0;
        font-family: var(--font-family);
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-scale-gray-4);
    }
}

.Property-1Selected {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 10px;
    background-color: var(--none);

    span {
        width: 7px;
        height: 24px;
        flex-grow: 0;
        font-family: var(--font-family);
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--primary-primary);
    }
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
    max-width: 100%;
}
.btn-md {
    width: 112px;
    height: 36px;
    padding: 0;
    border-radius: 6px;
    font-size: 12px;
}
.btn-lg {
    width: 156px;
    height: 48px;
    padding: 0;
    border-radius: 6px;
    font-size: 14px;
}
.btn-xl {
    width: 360px;
    height: 56px;
    padding: 0;
    border-radius: 8px;
    font-size: 16px;
}
.btn-cart {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border-color: $color-gray6;
}
.btn--primary {
    background-color: $color-primary;
    border-color: $color-primary;
}
.btn--outline_primary {
    background-color: $color-white;
    border-color: $color-primary;
}
.btn--outline_black {
    background-color: $color-white;
    border-color: $color-black;
}
.btn--outline_gray {
    background-color: $color-white;
    border-color: $color-gray5;
}
.btn--disabled {
    background-color: $color-gray6;
    border-color: $color-gray6;
    color: $color-gray4;
    cursor: default;
    pointer-events: none;
}
.btn-auth {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    min-width: 112px;
    height: 44px;
    border-radius: 6px;
    background-color: $color-primary;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;
}
