@import "resources/styles/origin.scss";

.footer {
    position: relative;
    background-color: $color-gray7;
    padding: 36px 0 40px 0;
    transition: all 0.3s;
    z-index: -1;

    &__content {
        max-width: 1660px;
        margin: 0 auto;

        &__logo {
            img {
                width: 120px;
            }
        }
        &__detail {
            margin-top: 21px;

            ul {
                display: flex;
                align-items: center;

                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 12px;
                    padding-right: 12px;
                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        content: "";
                        width: 1px;
                        height: 15px;
                        background-color: #666;
                    }
                    &:last-child::after {
                        background-color: none;
                        width: 0;
                        height: 0;
                    }
                    span {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: normal;
                        text-align: left;
                        color: $color-gray2;
                    }
                }
            }
        }
        &__terms {
            margin-top: 28px;

            ul {
                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 12px;
                    padding-right: 12px;
                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        content: "";
                        width: 1px;
                        height: 16px;
                        background-color: #707070;
                    }
                    &:last-child::after {
                        background-color: none;
                        width: 0;
                        height: 0;
                    }
                    a {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.75;
                        letter-spacing: normal;
                        text-align: left;
                        color: $color-black;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
