@import "resources/styles/origin.scss";

.notFound {
    position: relative;
    top: -80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: calc(100vh - 80px);
    > img {
    }
    > h1 {
        font-size: 34px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000;
    }
    > button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 60px;
        background-color: $color-primary;
        border-radius: 4px;
        > span {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: -0.32px;
            text-align: left;
            color: #000;
        }
    }
}
