@import "resources/styles/origin.scss";

.yourPw {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 0;

  &__logo {
    margin-top: 191px;

    img {
      width: 100%;
    }
  }

  &__mainTxt {
    margin-top: 32px;
    text-align: center;

    em {
      font-weight: 700;
    }
  }

  &__subTxt {
    margin-top: 16px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 59px;

    a:first-child {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 480px) {
  .yourPw {
    &__logo {
      margin-top: 80px;
    }
  }
}