.headerInner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1660px;
    margin-inline: auto;
    padding: 27px 0;
    z-index: 1;
}

.headerLeft {
    display: flex;
    align-items: center;
    gap: 40px;

    .logo {
        width: 140px;

        > img {
            width: 100%;
        }
    }

    .gnb {
        display: flex;
        align-items: center;
        gap: 35px;

        > li {
            > button,
            a {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 3;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                transition: color 0.15s;
                cursor: pointer;
            }

            // &:hover {
            //     > a {
            //         color: #fdc02f;
            //     }
            // }
            &.active {
                > button,
                a {
                    color: #fdc02f;
                }
            }
        }
    }
}

.headerRight {
    display: flex;
    align-items: center;
    gap: 12px;

    .gsb {
        .inputSearch {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 360px;
            height: 44px;
            border: 1px solid #ced4da;
            background-color: #fff;
            border-radius: 6px;
            padding: 0 12px;
            transition: border-color 0.15s;

            &:focus-within {
                border-color: #fdc02f;
            }

            > input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 15px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 3.33;
                letter-spacing: normal;
                text-align: left;
                color: #000;
                font-family: var(--font-family);
            }

            > button {
                display: inline-flex;
                align-items: center;
            }
        }
    }

    .gob {
        display: flex;
        align-items: center;

        li {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            padding: 0 8px;
            z-index: 100;

            path {
                fill: #bfbfbf;
                transition: fill 0.15s;
            }

            &:hover {
                path {
                    fill: #fdc02f;
                }
            }

            &.active {
                path {
                    fill: #fdc02f;
                }
            }

            &.user {
                .modalMypage {
                    display: none;
                }

                &:hover {
                    .modalMypage {
                        display: block;
                        position: absolute;
                        top: calc(100% - 0px);
                        right: 0;
                        background-color: #fff;
                        width: 160px;
                        border: 1px solid var(--gray-scale-gray-3);

                        a {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 44px;
                            font-size: 13px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1;
                            letter-spacing: normal;
                            text-align: center;
                            color: #000;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
