// tab
.tabWrapper {
    margin-top: 20px;

    .tabHeader {
        display: flex;
        gap: 10px;

        >button {
            min-width: 96px;
            height: 48px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            border-radius: 8px;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px var(--gray-scale-white);
            background-color: var(--gray-scale-white);
            transition: 0.25s;
            will-change: background-color, border-color;

            >span {
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--gray-scale-gray-6);
            }

            &.tabActive {
                border: solid 1px var(--primary-primary);
                background-color: var(--primary-primary);

                >span {
                    color: var(--gray-scale-white);
                }
            }
        }
    }
}