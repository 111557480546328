// detail
.detailWrapper {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--gray-scale-white);

    // header
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 20px;
        border-bottom: 1px solid var(--gray-scale-gray-3);
        ;

        .title {
            display: flex;
            align-items: center;
            gap: 12px;

            .btnPrev {}

            .titleName {
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #3b3f4a;
            }
        }

        .tableOption {
            display: flex;
            align-items: center;
            gap: 10px;

            >button {}
        }
    }

    // contents
    .contents {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 20px 20px 40px 20px;

        .itemWrapper {
            .wrapperHead {
                display: flex;
                justify-content: space-between;
                align-self: start;
                margin-bottom: 8px;

                .headTit {
                    display: inline-flex;
                    align-items: center;

                    >span {
                        font-size: 18px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: var(--gray-scale-gray-7);
                    }

                    .btnEdit {
                        margin-left: 12px;
                    }
                }

                .headOption {
                    display: flex;
                    gap: 6px;
                }
            }

            .wrapperBody {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 20px;

                .itemCont {
                    width: calc((100% / 2) - 10px);
                    border: 1px solid var(--gray-scale-gray-3);

                    &:only-child {
                        width: 100%;
                    }

                    .contHead {
                        padding: 10px 20px;
                        border-bottom: 2px solid var(--gray-scale-gray-7);

                        >span {
                            font-size: 16px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: var(--gray-scale-gray-7);
                        }
                    }

                    .contBody {
                        .item {
                            display: flex;

                            &:not(:last-child) {
                                border-bottom: 1px solid var(--gray-scale-gray-3);
                            }

                            .itemTit {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                flex-shrink: 0;
                                width: 123px;
                                padding: 10px 0;
                                border-right: 1px solid var(--gray-scale-gray-3);
                                text-align: center;

                                >span {
                                    font-size: 16px;
                                    font-weight: 600;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    text-align: center;
                                    color: var(--gray-scale-gray-7);
                                }
                            }

                            .itemDesc {
                                width: 100%;
                                padding: 10px 20px;

                                >span {
                                    font-size: 14px;
                                    font-weight: normal;
                                    font-stretch: normal;
                                    font-style: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    text-align: left;
                                    color: var(--gray-scale-gray-7);
                                }

                                // images
                                .imgList {
                                    display: flex;
                                    gap: 12px;
                                    overflow-x: scroll;

                                    .imgItem {
                                        position: relative;
                                        width: 102px;
                                        height: 102px;
                                        flex-grow: 0;
                                        flex-shrink: 0;
                                        opacity: 0.8;
                                        border-radius: 4px;
                                        background-color: #d9d9d9;
                                        overflow: hidden;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }

                                        .btnRemoveImg {
                                            display: none;
                                            content: "";
                                            position: absolute;
                                            top: 6px;
                                            right: 6px;
                                            width: 16px;
                                            height: 16px;
                                            background: url("/resources/icon/icon-imgClose.svg") center center no-repeat;
                                            background-size: cover;
                                        }
                                    }

                                    .image-empty {
                                        position: relative;
                                        background-color: #e8e8e8;
                                        width: 188px;
                                        height: 188px;
                                        border-radius: 12px;
                                        border: 1px solid #e8e8e8;
                                        overflow: hidden;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                        }

                                        &::after {
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            content: "";
                                            width: 24px;
                                            height: 24px;
                                            // background: url("../../resources/icons/icon-camera.svg");
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                @media (max-width: 767px) {
                    .itemCont {
                        width: 100%;
                    }
                }
            }

            table.detailTable {
                width: 100%;
                border: solid #e4e6ea;
                border-width: 0 1px 0 1px;
                table-layout: auto;

                tr {
                    border: solid #e4e6ea;
                    border-width: 1px 0 0 0;

                    &:last-child {
                        border-width: 1px 0 1px 0;
                    }
                }

                th,
                td {
                    min-width: 44px;
                    height: 44px;
                    padding: 4px 12px;
                    vertical-align: middle;
                    border: 1px solid #e4e6ea;

                    input[type="checkbox"] {
                        cursor: pointer;
                    }
                }

                thead {
                    border-bottom: 2px solid #5a5e6a;

                    tr {
                        th {
                            padding: 8px 0;

                            span {
                                font-size: 16px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                text-align: center;
                                color: var(--gray-scale-gray-7);
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            .cellTit {
                                display: none;
                                font-size: 12px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                text-align: left;
                                color: var(--gray-scale-black);
                            }

                            .cell {
                                font-size: 14px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                letter-spacing: normal;
                                text-align: left;
                                color: var(--gray-scale-gray-7);
                                line-height: normal;

                                &.cellOH {
                                    display: inline-block;
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    text-align: center;
                                    padding-left: 8px;
                                    padding-right: 8px;
                                }

                                &.cellOH2 {
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    width: 100%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    padding-left: 8px;
                                    padding-right: 8px;
                                    text-align: center;

                                    &:hover {
                                        display: inline-block;
                                        overflow: visible;
                                        text-overflow: unset;
                                    }
                                }

                                .summaries {
                                    display: none;

                                    .cellSummary {}
                                }
                            }

                            .summaries {
                                display: none;

                                .cellSummary {}
                            }
                        }

                        .moreDetails {
                            display: none;
                        }
                    }
                }

                td:nth-child(1) {
                    width: 2.8%;
                    padding: 0;
                    text-align: center;
                }

                td:nth-child(2) {
                    width: 15.2%;
                    text-align: center;
                }

                td:nth-child(3) {
                    width: 15.2%;
                    text-align: center;
                }

                td:nth-child(4) {
                    width: 15.2%;
                    text-align: right;
                }

                td:nth-child(5) {
                    width: 15.2%;
                    text-align: center;
                }

                td:nth-child(6) {
                    width: 15.2%;
                    text-align: right;
                }

                td:nth-child(7) {
                    width: 8.2%;
                    text-align: center;
                }

                td:nth-child(8) {
                    width: 8.2%;
                    text-align: center;
                }

                td:nth-child(9) {
                    width: 4.4%;
                    text-align: center;
                }

                @media (max-width: 767px) {

                    // 모바일 최적화 테이블 (컬럼형)
                    td {}

                    thead {
                        border: none;

                        tr {
                            th {
                                display: none;
                            }
                        }
                    }

                    tbody {
                        tr {
                            position: relative;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: flex-end;
                            justify-content: flex-end;
                            padding: 10px 12px;
                            border-width: 0 0 1px 0;

                            td {
                                position: relative;
                                display: flex;
                                align-items: flex-start;
                                border: none;
                                padding: 0;
                                height: auto;
                                min-height: auto;

                                .cellTit {
                                    display: block;
                                    width: 100px;
                                    flex-shrink: 0;
                                }

                                .cell {
                                    justify-content: flex-start;
                                    text-align: left;
                                    font-size: 12px;
                                    line-height: normal;
                                    text-align: left;
                                    color: var(--gray-scale-black);

                                    .pcCell {
                                        display: none;
                                    }

                                    .summaries {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        gap: 15px;
                                        margin-left: 15px;

                                        .cellSummary {}
                                    }

                                    button {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100%;
                                        min-height: 24px;
                                        padding: 0 10px;
                                        margin: 0 auto;
                                        border-radius: 8px;
                                        border: solid 1px var(--primary-100);
                                        font-size: 12px;
                                        font-weight: 500;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: normal;
                                        letter-spacing: normal;
                                        text-align: center;
                                        color: var(--primary-primary);

                                        >span {
                                            color: inherit;
                                        }
                                    }
                                }

                                &[data-mobile-view="show"] {
                                    width: 100%;
                                    // width: calc(100% - 42px);
                                    background-color: var(--gray-scale-gray-1);
                                    padding: 4px 20px;

                                    &:nth-of-type(2) {
                                        margin-top: 12px;
                                        padding-top: 12px;
                                    }
                                }

                                &[data-type="summaries"] {
                                    width: 100%;
                                    background-color: #fff;
                                }

                                &[data-type="button-1"] {
                                    padding-bottom: 12px;

                                    >span {
                                        width: 100%;
                                    }
                                }

                                &[data-type="button-2"] {
                                    width: calc(50%);
                                    // width: calc(50% - 21px);
                                    padding: 4px 20px 12px 20px;

                                    &:nth-of-type(1) {
                                        background-color: red;
                                    }

                                    >span {
                                        width: 100%;
                                    }
                                }

                                &:nth-child(n + 2) {
                                    display: none;
                                }

                                .moreDetails {
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    right: 0px;
                                    transform: translateY(-50%) rotate(180deg);
                                }

                                .moreButton {
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    right: 0px;
                                    transform: translateY(-50%);
                                }
                            }

                            &:last-child {
                                border-width: 0 0 1px 0;
                            }

                            &.open {
                                td {
                                    &:nth-child(n + 2) {
                                        display: flex;

                                        &[data-mobile-view="show"] {
                                            display: flex;
                                        }

                                        &[data-mobile-view="hide"] {
                                            display: none;
                                        }
                                    }
                                }

                                .moreDetails {
                                    transform: translateY(-50%) rotate(0deg);
                                }
                            }

                            &.total {
                                justify-content: flex-start;

                                [data-mobile-view="show"] {
                                    display: flex;
                                    width: auto;
                                    background-color: transparent;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            .itemButtonsRight {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;

                @media (max-width: 767px) {
                    margin-top: 20px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .header {
            .title {
                .titleName {
                    font-size: 18px;
                }
            }

            .tableOption {
                display: block;
                width: 24px;
                height: 24px;
                background: url("/resources/icon/MoreBold.svg") center center no-repeat;
                background-size: cover;
                cursor: pointer;

                .optionList {
                    display: none;

                    .selectFilter {
                        display: none;
                    }
                }

                &.on {
                    position: relative;

                    .optionList {
                        position: absolute;
                        top: 30px;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 12px;
                        width: max-content;
                        padding: 12px 14px;
                        border-radius: 8px;
                        border: solid 1px var(--gray-scale-gray-3);
                        background-color: var(--gray-scale-white);
                        z-index: 5;

                        >button {
                            min-width: auto;
                            height: auto;
                            padding: 0;
                            border: none;
                            border-radius: 0;

                            &.btnMain {
                                font-size: 14px;
                            }

                            &.primaryOutline {}

                            &.grayOutline {}
                        }
                    }
                }
            }
        }

        .contents {
            gap: 20px;

            .itemWrapper {

                .wrapperBody {

                    .itemCont {

                        .contBody {

                            .item {

                                .itemDesc {

                                    .imgList {
                                        gap: 10px 8px;
                                        flex-wrap: wrap;
                                        overflow-x: auto;

                                        .imgItem {
                                            width: calc((100% / 3) - 6px);
                                            height: auto;
                                            aspect-ratio: auto 1/1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}