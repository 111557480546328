.progress-bar {
  width: 100%;
  background-color: var(--gray-scale-gray-6);

  .progress {
    background-color: var(--primary-100);
    height: 4px;
    transition: width 0.85s 0.25s linear;
  }
}
