@import "react-datepicker/dist/react-datepicker";

// Datepicker
.react-datepicker {
  position: absolute;
  top: 0;
  left: -20px;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-size: 14px;
  padding: 12px 8px;
  border-radius: 12px;
  box-shadow: 3px 3px 6px 2px rgba(0, 0, 0, 0.16);
  border: 1px solid var(--gray-scale-gray-3);
  z-index: 110;
  transform: scale(0.95);
  
}

// header
.react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid var(--gray-scale-gray-3);
  text-align: left;
  padding: 0;
}

.react-datepicker__month {
  margin: 12px 0 0 0;
}

// header arrow
.react-datepicker__navigation-icon {
  padding: 0 !important;
  height: unset !important;
  width: unset !important;
  display: unset !important;
}

.react-datepicker__navigation--previous {
  top: 14px;
}

.react-datepicker__navigation--next {
  top: 14px;
}

.react-datepicker__navigation-icon--next {
}

.react-datepicker__navigation-icon--prev {
}

.react-datepicker__navigation-icon--next::before {
  left: -16px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -16px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  width: 8px !important;
  height: 8px !important;
  border-width: 2px 2px 0 0;
  padding: 0 !important;
}

// nav
.react-datepicker__current-month {
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-scale-black);
}

// cells
.react-datepicker__day-name,
.react-datepicker__day {
  width: 36px;
  height: 36px;
  margin: 0 4px;
  transition: 0.1s;
  vertical-align: middle;
  line-height: 32px;
}

// weeks
.react-datepicker__day-names,
.react-datepicker__week {
  margin-bottom: 0;
}

.react-datepicker__day-names {
  margin: 10px 0 0 0;

  .react-datepicker__day-name {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070 !important;
  }
}

// days
.react-datepicker__day-names,
.react-datepicker__week {
  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: 14px;
    font-weight: 400;
    color: #000;
  }
}

// today
.react-datepicker__day--today {
  background-color: #fff !important;
  border: 1px solid #fdc02f;
  border-radius: 50%;

  &.react-datepicker__day--selected {
    background-color: #fdc02f !important;
    border: 1px solid #fdc02f;
    color: #fff;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: transparent;
  color: #000;
}

// hover
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border-radius: 50%;
  background-color: var(--primary-primary);
  color: #226bef !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 50%;
  background-color: var(--gray-scale-gray-3);
  color: #000 !important;
}

// disabled date
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: var(--gray-scale-gray-4) !important;
  text-decoration: line-through;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
  color: var(--gray-scale-gray-4) !important;
  pointer-events: none;
}

// disabled other monthes highlight effect
.react-datepicker__day[tabindex="0"] {
  background-color: #fdc02f;
  color: #000;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: #fdc02f;
  color: #000;
  border-radius: 50%;
}

.react-datepicker__day--outside-month {
  color: var(--gray-scale-gray-3) !important;
  text-decoration: unset;
}

// inputs
.react-datepicker-wrapper {
  height: 100%;
}

.react-datepicker__input-container {
  position: relative;
  height: 100%;
}

// custom
.cd-header {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 47px;

  .ch-tit {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--gray-scale-black);
  }

  .cd-nav {
    margin: 0 12px;
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }

  .cd-arrow {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    .icon {
      display: flex;
      align-items: center;
      font-size: 22px;

      polyline {
        stroke: var(--gray-scale-gray-3);
      }
    }
  }
}

// .timepicker {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 200px;
//   background-color: red;
//   z-index: 10;
// }
.datePickerBottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  border-top: 1px solid var(--gray-scale-gray-2);

  .timepicker {
    position: relative;
    display: flex;
    width: 100%;
    gap: 10px;

    .timeSelector {
      position: relative;

      &:nth-child(1) {
      }

      &:nth-child(2) {
        margin-right: 10px;

        &::after {
          content: ":";
          position: absolute;
          top: 2px;
          right: -12px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--gray-scale-gray-7);
        }
      }

      &:nth-child(3) {
      }
    }
  }

  > button {
    min-width: 58px;
    height: 26px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 0;
    border-radius: 50%;
    border: solid 1px var(--primary-200);
    background-color: var(--gray-scale-white);

    > span {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: var(--primary-primary);
    }
  }
}

// .react-datepicker__navigation--next {
//   background: red;
//   width: 15px;
//   height: 15px;
//   border: none;
// }

// month picker
.react-datepicker__month-container {
  .react-datepicker__header {
    text-align: center;
    padding: 0 0;
  }
}

.react-datepicker__month {
  display: flex;
  flex-wrap: wrap;
  // gap: 10px 0;
}

.react-datepicker__month-wrapper {
  display: flex;
}

.react-datepicker__month-text {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.react-datepicker__month-text--today {
  // color: var(--primary-primary);
}

.react-datepicker__month--selected:hover {
  color: var(--gray-scale-white) !important;
}

.react-datepicker__month-text:hover {
}

.react-datepicker__month-text--keyboard-selected {
  border-radius: 50%;
  background-color: var(--primary-100);
  color: #226bef !important;
}

.react-datepicker__month-text--today {
  font-weight: normal;
}

// Timepicker
.react-datepicker-time__header {
  text-align: center;
  padding: 4px 0;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #fdc02f;
  font-weight: normal;
}

.react-datepicker__children-container {
  width: 100%;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  width: -webkit-fill-available;
  position: absolute;
  top: 4%;
  left: 30%;
}
.react-datepicker__year-read-view--selected-year {
  display: none !important;
}
.datePickerBottom .timepicker .timeSelector {
  width: 100%;
}

.react-datepicker__year-option--selected {
  display: unset !important;
  justify-content: unset !important;
  width: unset !important;
  height: unset !important;
  padding: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
  color: unset !important;
  display: none !important;
}

// custom
.custom-datepicker {border: none;}