// Table
.modaltable {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    // border-radius: 8px;
    // box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--gray-scale-white);
    margin-top: 20px;

    // Table Header
    .tableHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        // Search Options
        .searchOption {
            display: flex;
            align-items: center;
            gap: 10px;

            .selectFilter {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 48px;
                padding: 12px 10px;
                border-radius: 8px;
                border: solid 1px var(--gray-scale-gray-3);
                transition: 0.35s;
                will-change: border;

                @media (max-width: 767px) {
                    display: none;
                }

                >select {
                    min-width: 58px;
                    border: none;
                    outline: none;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: right;
                    color: var(--gray-scale-black);

                    >option {
                        text-align: center;
                    }
                }

                &:focus-within {
                    border: solid 1px var(--primary-primary);
                }
            }

            .inputSearch {
                width: 254px;
                height: 48px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0 16px;
                border-radius: 8px;
                border: solid 1px var(--gray-scale-gray-3);
                transition: 0.35s;
                will-change: border;

                .textEsset {
                    flex-shrink: 0;

                    >img {}
                }

                >input {
                    width: 100%;
                    height: 100%;
                    padding-left: 12px;
                }

                &:focus-within {
                    border: solid 1px var(--primary-primary);
                }
            }

            .buttonSearch {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: 48px;
                padding: 0 12px;
                border-radius: 8px;
                border: solid 1px var(--gray-scale-gray-3);
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--gray-scale-black);
            }

            @media (max-width: 767px) {
                width: 100%;

                .selectFilter {
                    display: none;
                }

                .inputSearch {
                    width: calc(100% - 34px);
                }

                .buttonSearch {
                    display: none;
                }
            }
        }

        // Table Options 
        .tableOption {
            display: flex;
            gap: 10px;

            @media (max-width: 767px) {
                display: none;
            }
        }

        // Data Info
        .dataInfo {
            display: flex;
            align-items: center;
            gap: 20px;

            .pageBack {
                >button {
                    >img {}
                }
            }

            .dataTitle {
                .carName {
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--gray-scale-gray-7);
                }
            }
        }
    }

    // Table Tab
    .tableTab {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 40px);
            height: 1px;
            background-color: var(--gray-scale-gray-3);
            margin: 0 -20px 0 -20px;
        }

        .tabButtons {
            display: flex;

            >button {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                padding: 12px 22px 12px 22px;
                background-color: var(--none);
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: var(--gray-scale-gray-4);
                border-bottom: 3px solid transparent;

                &.active {
                    border-color: var(--primary-primary);
                    color: var(--primary-primary);
                }
            }

            // Search Options
            .searchOption {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-top: 20px;

                .selectFilter {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    height: 48px;
                    padding: 12px 10px;
                    border-radius: 8px;
                    border: solid 1px var(--gray-scale-gray-3);
                    transition: 0.35s;
                    will-change: border;

                    @media (max-width: 767px) {
                        display: none;
                    }

                    >select {
                        min-width: 58px;
                        border: none;
                        outline: none;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: right;
                        color: var(--gray-scale-black);

                        >option {
                            text-align: center;
                        }
                    }

                    &:focus-within {
                        border: solid 1px var(--primary-primary);
                    }
                }

                .inputSearch {
                    width: 254px;
                    height: 48px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 16px;
                    border-radius: 8px;
                    border: solid 1px var(--gray-scale-gray-3);
                    transition: 0.35s;
                    will-change: border;

                    .textEsset {
                        flex-shrink: 0;

                        >img {}
                    }

                    >input {
                        width: 100%;
                        height: 100%;
                        padding-left: 12px;
                    }

                    &:focus-within {
                        border: solid 1px var(--primary-primary);
                    }
                }

                .buttonSearch {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    height: 48px;
                    padding: 0 12px;
                    border-radius: 8px;
                    border: solid 1px var(--gray-scale-gray-3);
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--gray-scale-black);
                }
            }
        }
    }

    // Table Contents
    .table-content {

        // min-height: 682px;
        caption {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-7);
            margin-bottom: 20px;
        }

        // Table
        table {
            width: 100%;
            border: solid #e4e6ea;
            border-width: 0 1px 0 1px;
            table-layout: fixed;

            tr {
                border: solid #e4e6ea;
                border-width: 1px 0 0 0;

                &:last-child {
                    border-width: 1px 0 1px 0;
                }
            }

            th,
            td {
                min-width: 44px;
                min-height: 44px;
                padding: 10px 0;
                border: 1px solid #e4e6ea;

                input[type="checkbox"] {
                    cursor: pointer;
                }

                &[data-align="left"] {
                    text-align: left;
                    padding: 10px 12px;
                }

                &[data-align="center"] {
                    text-align: center;
                }
            }

            thead {
                border-bottom: 2px solid #5a5e6a;

                tr {
                    th {
                        padding: 10px 0;

                        span {
                            font-size: 16px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: var(--gray-scale-gray-7);
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        .cellTit {
                            display: none;
                            font-size: 12px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: var(--gray-scale-black);
                        }

                        .cell {
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: var(--gray-scale-gray-7);

                            .summaries {
                                display: none;

                                .cellSummary {}
                            }
                        }

                        button {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            min-height: 24px;
                            padding: 0 10px;
                            margin: 0 auto;
                            border-radius: 8px;
                            border: solid 1px var(--primary-100);
                            font-size: 12px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: var(--primary-primary);
                        }

                        .moreDetails {
                            display: none;
                        }
                    }
                }

                // 선택 가능한 rows
                &.selectable {
                    tr {
                        cursor: pointer;

                        &:hover {
                            background-color: var(--gray-scale-gray-3);
                        }
                    }
                }
            }

            // 가로 유지 모바일 테이블
            &.steady {
                width: 100%;

                th,
                td {
                    &[data-mobile-view="show"] {
                        width: auto !important;
                    }

                    &[data-mobile-view="hide"] {
                        display: none;
                    }

                    &:nth-child(1) {
                        width: 44px !important;
                    }
                }

                thead {
                    width: 100%;

                    tr {
                        width: 100%;

                        th {}
                    }
                }

                tbody {
                    tr {
                        td {}
                    }
                }
            }

            @media (max-width: 767px) {

                // 모바일 최적화 테이블 (컬럼형)
                &.mobile {
                    td {}

                    thead {
                        border: none;

                        tr {
                            th {
                                display: none;
                            }
                        }
                    }

                    tbody {
                        tr {
                            position: relative;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: flex-end;
                            justify-content: flex-end;
                            // flex-direction: column;
                            // padding: 45px 12px 12px 45px;
                            padding: 12px;
                            border-width: 0 0 1px 0;

                            td {
                                position: relative;
                                display: flex;
                                align-items: flex-start;
                                border: none;
                                padding: 0;
                                min-height: auto;

                                .cellTit {
                                    display: block;
                                    width: 100px;
                                    flex-shrink: 0;
                                }

                                .cell {
                                    justify-content: flex-start;
                                    text-align: left;
                                    font-size: 12px;
                                    line-height: normal;
                                    text-align: left;
                                    color: var(--gray-scale-black);

                                    .summaries {
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        gap: 15px;
                                        margin-left: 15px;

                                        .cellSummary {}
                                    }

                                    button {
                                        width: 100%;
                                    }
                                }

                                &[data-mobile-view="show"] {
                                    width: 100%;
                                    // width: calc(100% - 42px);
                                    background-color: var(--gray-scale-gray-1);
                                    padding: 4px 20px;

                                    &:nth-of-type(2) {
                                        margin-top: 12px;
                                        padding-top: 12px;
                                    }
                                }

                                &[data-type="summaries"] {
                                    width: 100%;
                                    background-color: #fff;
                                }

                                &[data-type="button-1"] {
                                    padding-bottom: 12px;

                                    >span {
                                        width: 100%;
                                    }
                                }

                                &[data-type="button-2"] {
                                    width: calc(50%);
                                    // width: calc(50% - 21px);
                                    padding: 4px 20px 12px 20px;

                                    &:nth-of-type(1) {
                                        background-color: red;
                                    }

                                    >span {
                                        width: 100%;
                                    }
                                }

                                &:nth-child(n + 2) {
                                    display: none;
                                }

                                .moreDetails {
                                    display: block;
                                    position: absolute;
                                    top: 50%;
                                    right: 0px;
                                    transform: translateY(-50%) rotate(180deg);
                                }
                            }

                            &:last-child {
                                border-width: 0 0 1px 0;
                            }

                            &.open {
                                td {
                                    &:nth-child(n + 2) {
                                        display: flex;

                                        &[data-mobile-view="show"] {
                                            display: flex;
                                        }

                                        &[data-mobile-view="hide"] {
                                            display: none;
                                        }
                                    }
                                }

                                .moreDetails {
                                    transform: translateY(-50%) rotate(0deg);
                                }
                            }
                        }
                    }
                }
            }

            // @media (max-width: 767px) {
            //     td {}

            //     thead {
            //         border: none;

            //         tr {
            //             th {
            //                 display: none;
            //             }
            //         }
            //     }

            //     tbody {
            //         tr {
            //             position: relative;
            //             display: flex;
            //             align-items: flex-end;
            //             flex-direction: column;
            //             // padding: 45px 12px 12px 45px;
            //             padding: 12px;
            //             border-width: 0 0 1px 0;

            //             td {
            //                 position: relative;
            //                 display: flex;
            //                 align-items: center;
            //                 border: none;
            //                 padding: 0;
            //                 min-height: auto;

            //                 .cellTit {
            //                     display: block;
            //                     width: 100px;
            //                     flex-shrink: 0;
            //                 }

            //                 .cell {
            //                     justify-content: flex-start;
            //                     text-align: left;
            //                     font-size: 12px;
            //                     line-height: normal;
            //                     text-align: left;
            //                     color: var(--gray-scale-black);

            //                     .summaries {
            //                         display: flex;
            //                         align-items: center;
            //                         width: 100%;
            //                         gap: 15px;
            //                         margin-left: 15px;

            //                         .cellSummary {}
            //                     }

            //                     button {
            //                         width: 100%;
            //                     }
            //                 }

            //                 &[data-mobile-view="show"] {
            //                     width: calc(100% - 42px);
            //                     background-color: var(--gray-scale-gray-1);
            //                     padding: 4px 20px;

            //                     &:nth-of-type(2) {
            //                         margin-top: 12px;
            //                         padding-top: 12px;
            //                     }
            //                 }

            //                 &[data-type="summaries"] {
            //                     width: 100%;
            //                     background-color: #fff;
            //                 }

            //                 &[data-type="button"] {
            //                     padding-bottom: 12px;

            //                     >span {
            //                         width: 100%;
            //                     }
            //                 }

            //                 &:nth-child(n + 2) {
            //                     display: none;
            //                 }

            //                 .moreDetails {
            //                     display: block;
            //                     position: absolute;
            //                     top: 50%;
            //                     right: 12px;
            //                     transform: translateY(-50%) rotate(180deg);
            //                 }
            //             }

            //             &:last-child {
            //                 border-width: 0 0 1px 0;
            //             }

            //             &.open {
            //                 td {
            //                     &:nth-child(n + 2) {
            //                         display: flex;

            //                         &[data-mobile-view="show"] {
            //                             display: flex;
            //                         }

            //                         &[data-mobile-view="hide"] {
            //                             display: none;
            //                         }
            //                     }
            //                 }

            //                 .moreDetails {
            //                     transform: translateY(-50%) rotate(0deg);
            //                 }
            //             }
            //         }
            //     }
            // }
        }

        @media (max-width: 767px) {
            min-height: calc(100vh - 366px);
        }
    }

    // Table Footer
    .tableFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pageGuide {
            .curStats {
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: var(--gray-scale-gray-4);
            }
        }

        .pagination {
            display: flex;
            align-items: center;
            gap: 8px;

            .pagiPrev {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                >img {}
            }

            .pagiNext {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                >img {}
            }

            >ul {
                display: flex;
                align-items: center;
                gap: 8px;

                >li {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    cursor: pointer;

                    >span {
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: var(--gray-scale-gray-4);
                    }

                    &.active {
                        span {
                            color: var(--primary-primary);
                        }
                    }

                    &.disabled {
                        span {
                            color: var(--gray-scale-gray-4);
                        }
                    }
                }
            }

            .lastPage {
                position: relative;
                padding-left: 15px;

                &::before {
                    position: absolute;
                    content: "...";
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--gray-scale-gray-4);
                }

                li {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--gray-scale-gray-4);
                    cursor: pointer;
                }
            }
        }
    }
}

[data-mobile-view="1"] {
    width: 1% !important;
}

[data-mobile-view="2"] {
    width: 2% !important;
}

[data-mobile-view="3"] {
    width: 3% !important;
}

[data-mobile-view="4"] {
    width: 4% !important;
}

[data-mobile-view="5"] {
    width: 5% !important;
}

[data-mobile-view="6"] {
    width: 6% !important;
}

[data-mobile-view="7"] {
    width: 7% !important;
}

[data-mobile-view="8"] {
    width: 8% !important;
}

[data-mobile-view="9"] {
    width: 9% !important;
}

[data-mobile-view="10"] {
    width: 10% !important;
}

[data-mobile-view="11"] {
    width: 11% !important;
}

[data-mobile-view="12"] {
    width: 12% !important;
}

[data-mobile-view="13"] {
    width: 13% !important;
}

[data-mobile-view="14"] {
    width: 14% !important;
}

[data-mobile-view="15"] {
    width: 15% !important;
}

[data-mobile-view="16"] {
    width: 16% !important;
}

[data-mobile-view="17"] {
    width: 17% !important;
}

[data-mobile-view="18"] {
    width: 18% !important;
}

[data-mobile-view="19"] {
    width: 19% !important;
}

[data-mobile-view="20"] {
    width: 20% !important;
}

[data-mobile-view="21"] {
    width: 21% !important;
}

[data-mobile-view="22"] {
    width: 22% !important;
}

[data-mobile-view="23"] {
    width: 23% !important;
}

[data-mobile-view="24"] {
    width: 24% !important;
}

[data-mobile-view="25"] {
    width: 25% !important;
}

[data-mobile-view="26"] {
    width: 26% !important;
}

[data-mobile-view="27"] {
    width: 27% !important;
}

[data-mobile-view="28"] {
    width: 28% !important;
}

[data-mobile-view="29"] {
    width: 29% !important;
}

[data-mobile-view="30"] {
    width: 30% !important;
}

[data-mobile-view="31"] {
    width: 31% !important;
}

[data-mobile-view="32"] {
    width: 32% !important;
}

[data-mobile-view="33"] {
    width: 33% !important;
}

[data-mobile-view="34"] {
    width: 34% !important;
}

[data-mobile-view="35"] {
    width: 35% !important;
}

[data-mobile-view="36"] {
    width: 36% !important;
}

[data-mobile-view="37"] {
    width: 37% !important;
}

[data-mobile-view="38"] {
    width: 38% !important;
}

[data-mobile-view="39"] {
    width: 39% !important;
}

[data-mobile-view="40"] {
    width: 40% !important;
}

[data-mobile-view="41"] {
    width: 41% !important;
}

[data-mobile-view="42"] {
    width: 42% !important;
}

[data-mobile-view="43"] {
    width: 43% !important;
}

[data-mobile-view="44"] {
    width: 44% !important;
}

[data-mobile-view="45"] {
    width: 45% !important;
}

[data-mobile-view="46"] {
    width: 46% !important;
}

[data-mobile-view="47"] {
    width: 47% !important;
}

[data-mobile-view="48"] {
    width: 48% !important;
}

[data-mobile-view="49"] {
    width: 49% !important;
}

[data-mobile-view="50"] {
    width: 50% !important;
}

[data-mobile-view="51"] {
    width: 51% !important;
}

[data-mobile-view="52"] {
    width: 52% !important;
}

[data-mobile-view="53"] {
    width: 53% !important;
}

[data-mobile-view="54"] {
    width: 54% !important;
}

[data-mobile-view="55"] {
    width: 55% !important;
}

[data-mobile-view="56"] {
    width: 56% !important;
}

[data-mobile-view="57"] {
    width: 57% !important;
}

[data-mobile-view="58"] {
    width: 58% !important;
}

[data-mobile-view="59"] {
    width: 59% !important;
}

[data-mobile-view="60"] {
    width: 60% !important;
}

[data-mobile-view="61"] {
    width: 61% !important;
}

[data-mobile-view="62"] {
    width: 62% !important;
}

[data-mobile-view="63"] {
    width: 63% !important;
}

[data-mobile-view="64"] {
    width: 64% !important;
}

[data-mobile-view="65"] {
    width: 65% !important;
}

[data-mobile-view="66"] {
    width: 66% !important;
}

[data-mobile-view="67"] {
    width: 67% !important;
}

[data-mobile-view="68"] {
    width: 68% !important;
}

[data-mobile-view="69"] {
    width: 69% !important;
}

[data-mobile-view="70"] {
    width: 70% !important;
}

[data-mobile-view="71"] {
    width: 71% !important;
}

[data-mobile-view="72"] {
    width: 72% !important;
}

[data-mobile-view="73"] {
    width: 73% !important;
}

[data-mobile-view="74"] {
    width: 74% !important;
}

[data-mobile-view="75"] {
    width: 75% !important;
}

[data-mobile-view="76"] {
    width: 76% !important;
}

[data-mobile-view="77"] {
    width: 77% !important;
}

[data-mobile-view="78"] {
    width: 78% !important;
}

[data-mobile-view="79"] {
    width: 79% !important;
}

[data-mobile-view="80"] {
    width: 80% !important;
}

[data-mobile-view="81"] {
    width: 81% !important;
}

[data-mobile-view="82"] {
    width: 82% !important;
}

[data-mobile-view="83"] {
    width: 83% !important;
}

[data-mobile-view="84"] {
    width: 84% !important;
}

[data-mobile-view="85"] {
    width: 85% !important;
}

[data-mobile-view="86"] {
    width: 86% !important;
}

[data-mobile-view="87"] {
    width: 87% !important;
}

[data-mobile-view="88"] {
    width: 88% !important;
}

[data-mobile-view="89"] {
    width: 89% !important;
}

[data-mobile-view="90"] {
    width: 90% !important;
}

[data-mobile-view="91"] {
    width: 91% !important;
}

[data-mobile-view="92"] {
    width: 92% !important;
}

[data-mobile-view="93"] {
    width: 93% !important;
}

[data-mobile-view="94"] {
    width: 94% !important;
}

[data-mobile-view="95"] {
    width: 95% !important;
}

[data-mobile-view="96"] {
    width: 96% !important;
}

[data-mobile-view="97"] {
    width: 97% !important;
}

[data-mobile-view="98"] {
    width: 98% !important;
}

[data-mobile-view="99"] {
    width: 99% !important;
}

[data-mobile-view="100"] {
    width: 100% !important;
}