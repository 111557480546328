@import "resources/styles/origin.scss";

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 0 0;
  margin-top: -80px !important;

  &__slogan {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-top: 102px;

    em {
      font-weight: bold;
      color: var(--primary-primary);
    }
  }

  &__logo {
    margin-top: 10px;

    img {
      width: 307px;
    }
  }

  &__form {
    width: 100%;
    margin-top: 60px;
  }

  &__mnb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    li {
      position: relative;
      display: inline-flex;
      align-items: center;
      margin-right: 16px;
      padding-right: 16px;

      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 12px;
        background-color: var(--gray-scale-gray-6);
      }

      &:last-child::after {
        width: 0;
        height: 0;
        background-color: none;
      }

      a {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }
    }
  }
}