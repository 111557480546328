.button-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.form-field ~ .button-row {
    margin-top: 8px;
}

// Buttons
.btn {
    outline: none;

    &.btnMain {
        min-width: 80px;
        height: 40px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        border-radius: 4px;
        border: solid 1px var(--gray-scale-gray-3);
        background-color: rgba(255, 255, 255, 0);
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-scale-gray-7);
    }

    &.btnLarge {
        width: 100%;
        min-width: 332px;
        height: 48px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        border-radius: 8px;
        border: solid 1px var(--gray-scale-gray-3);
        background-color: rgba(255, 255, 255, 0);
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--primary-primary);
    }

    &.btnSmall {
        min-width: 41px;
        height: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2px;
        padding: 0 10px;
        border-radius: 8px;
        border: solid 1px var(--gray-scale-gray-3);
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--gray-scale-gray-7);
    }

    &.btnExcel {
        min-width: 48px;
        padding: 0;
        background: url("/resources/icon/icon-excel.svg") center center no-repeat;
        background-size: cover;
        font-size: 0;
    }

    &.btnMap {
        min-width: 48px;
        padding: 0;
        background: url("/resources/icon/icon-map.svg") center center no-repeat;
        background-size: auto;
        font-size: 0;
    }

    &.btnList {
        min-width: 48px;
        padding: 0;
        background: url("/resources/icon/icon-list.svg") center center no-repeat;
        background-size: auto;
        font-size: 0;
    }

    &.btnSign {
        margin-top: 20px;
        height: 56px;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
    }

    &.primaryFill {
        background-color: var(--primary-primary);
        border: solid 1px var(--primary-primary);
        color: var(--gray-scale-white);
    }

    &.primaryOutline {
        border: solid 1px var(--primary-primary);
        color: var(--primary-primary);
    }

    &.primaryOutline_100 {
        border: solid 1px var(--primary-100);
        color: var(--primary-primary);
    }

    &.grayFill {
        border: solid 1px var(--gray-scale-gray-9);
        background-color: var(--gray-scale-gray-9);
        color: var(--gray-scale-white) !important;
    }

    &.grayOutline {
        border: solid 1px var(--gray-scale-gray-3);
        background-color: rgba(255, 255, 255, 0);
        color: var(--gray-scale-gray-7);
    }

    &.disabled {
        border: solid 1px var(--gray-scale-gray-3);
        background-color: var(--gray-scale-gray-3);
        color: var(--gray-scale-gray-4);
        pointer-events: none;
    }

    @media (max-width: 767px) {
        max-width: 100%;

        &.btnLarge {
            min-width: auto;
            width: 100%;
        }

        &.btnExcel {
            display: none;
        }

        &.disabled {
            background-color: transparent;
        }
    }
}
