.body {
    width: 100%;
    min-width: 1660px;
    min-height: 100%;
    // background-color: var(--gray-scale-gray-2);
    display: flex;
    flex-direction: column;

    .main {
        width: 100%;
        max-width: 1660px;
        margin: 30px auto 80px;
    }

    footer {
        margin-top: auto;
    }
}
