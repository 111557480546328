// @import "resources/styles/origin.scss";

.modal {
    position: relative;
    z-index: 200;

    .dim {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 99;

        &.dim_transparent {
            background-color: transparent;
        }
    }

    .container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: 600px;
        max-width: 90%;
        height: 660px;
        background-color: #fff;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
        z-index: 100;

        .btn_close {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 14px;
            height: 14px;
            background: url("/resources/icon/close.svg") center center no-repeat;
            border: none;
            cursor: pointer;
        }
    }
}
