.verifyPhone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;

    .dim {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .join__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        background-color: #fff;
        width: 400px;
        max-width: 100%;
        padding: 20px;
        border-radius: 20px;
    }

    .join__buttons {
        display: flex;
        gap: 12px;
    }
}