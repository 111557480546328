@import "resources/styles/origin.scss";

.findPw {
  padding: 60px 0 120px 0;

  &__header {
    position: relative;
  }

  &__content {
    display: none;
    margin-top: 40px;

    &__visible {
      display: block;
    }

    &__mainTxt {
      display: block;
    }

    &__subTxt {
      margin-top: 8px;
    }

    &__termsBox {
      margin-top: 60px;

      &__all {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--gray-scale-gray-6);
      }

      &__normal {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__formContainer {
      margin-top: 36px;
    }
  }

  &__buttons {
    margin-top: 60px;
  }
}

.isVisible {
  display: block;
}

@media (max-width: 480px) {
  .findPw {
    padding: 40px 0 80px;
  }
}