/****************************** Design System ******************************/
$tablet: 1024px;
$mobile: 600px;

/* Color palette */
// Main-Color
$color-primary: #ffc108;
$color-secondary: #2c4762;
$color-third: #ff7a3b;
// System-Color
$color-susscess: #6cd15a;
$color-danger: #e64841;
// Grayscales
$color-black: #000000;
$color-gray1: #3d3d3d;
$color-gray2: #666666;
$color-gray3: #808080;
$color-gray4: #9f9f9f;
$color-gray5: #bfbfbf;
$color-gray6: #eaeaea;
$color-gray7: #f6f6f6;
$color-white: #ffffff;

/* Layout */
// $container: 1180px;
// $maxwidth: 100%;
// $minwidth: 1040px;
// $formWidth: 360px;
// $headerHeight: 80px;
// $spacing-md: 16px;
// $spacing-lg: 32px;

// @mixin breakpoint($point) {
//   @if $point == md {
//     // 768px
//     @media (min-width: 48em) {
//       @content;
//       $container: 100%;
//       $minwidth: 100%;
//       $headerHeight: 52px;
//     }
//   }
// }

$container: 1180px;
$maxwidth: 100%;
$minwidth: 1040px;
$formWidth: 360px;
$headerHeight: 80px;
$spacing-md: 16px;
$spacing-lg: 32px;

@mixin desktop {
    @media (min-width: #{$tablet}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$mobile}) and (max-width: #{$tablet - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile - 1px}) {
        @content;
    }
}

html,
body {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 14px;
}

.fwr {
    font-weight: 400;
}

.fwm {
    font-weight: 500;
}

.fwb {
    font-weight: 700;
}

h1 {
    font-size: 28px;
    line-height: 48px;
}

h2 {
    font-size: 24px;
    line-height: 40px;
}

h3 {
    font-size: 20px;
    line-height: 34px;
}

h4 {
    font-size: 18px;
    line-height: 32px;
}

.pl {
    font-size: 16px;
    line-height: 28px;
}

.pm {
    font-size: 14px;
    line-height: 24px;
}

.ps {
    font-size: 12px;
    line-height: 20px;
}

.heading-1 {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $color-black;
}

.heading-2 {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $color-black;

    &.fw-medium {
        font-weight: 500;
    }
}

.heading-3 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $color-black;
}

/****************************** Layout ******************************/
.global-width {
    min-width: $minwidth;
    width: $container;
    max-width: $maxwidth;
    margin: 0 auto;
}

.form-width {
    max-width: $formWidth;
    margin: 0 auto;
}

@media (max-width: 480px) {
    .global-width {
        min-width: auto;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .heading-1 {
        font-size: 28px;
    }
}

.form-container {
    .button-form {
        max-width: 100%;
    }

    .btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        border: 1px solid;
        max-width: 100%;
    }

    .btn-md {
        width: 112px;
        height: 36px;
        padding: 0;
        border-radius: 6px;
        font-size: 12px;
    }

    .btn-lg {
        width: 156px;
        height: 48px;
        padding: 0;
        border-radius: 6px;
        font-size: 14px;
    }

    .btn-xl {
        width: 360px;
        height: 56px;
        padding: 0;
        border-radius: 8px;
        font-size: 16px;
    }

    .btn-cart {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border-color: $color-gray6;
    }

    .btn--primary {
        background-color: $color-primary;
        border-color: $color-primary;
    }

    .btn--outline_primary {
        background-color: $color-white;
        border-color: $color-primary;
    }

    .btn--outline_black {
        background-color: $color-white;
        border-color: $color-black;
    }

    .btn--outline_gray {
        background-color: $color-white;
        border-color: $color-gray5;
    }

    .btn--disabled {
        background-color: $color-gray6;
        border-color: $color-gray6;
        color: $color-gray4;
        cursor: default;
        pointer-events: none;
    }

    .btn-channel {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border-color: transparent;
        background: url("../../resources/icon/button-channel.svg") center center no-repeat;
    }

    .btn-sns {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border-color: transparent;
    }

    .btn--kakao {
        background: url("../../resources/icon/button-kakao.svg") center center no-repeat;
    }

    .btn--facebook {
        background: url("../../resources/icon/button-facebook.svg") center center no-repeat;
    }

    .btn--naver {
        background: url("../../resources/icon/button-naver.svg") center center no-repeat;
    }

    .btn--link {
        background: url("../../resources/icon/button-link.svg") center center no-repeat;
    }

    @media (max-width: 480px) {
        .button-form {
            padding: 0 20px;
        }
    }

    .btn--third {
        width: 100%;
    }

    // Input
    .input-form {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &.required {
            .form-title {
                span::after {
                    content: "*";
                    position: absolute;
                    top: -3px;
                    margin-left: 4px;
                    font-size: 18px;
                    font-weight: 700;
                    color: #ea5a00;
                }
            }

            .title-sub {
                padding-left: 16px !important;
            }
        }

        .form-title {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            span {
                position: relative;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray2;

                &.fz16 {
                    font-size: 16px;
                    line-height: 1.25;
                    letter-spacing: normal;
                }
            }

            .title-sub {
                position: relative;
                font-size: 12px;
                color: #ea5a00;
                padding-left: 4px;
            }

            a {
                display: inline-flex;
                align-items: center;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: left;
                color: $color-primary;
                cursor: pointer;
            }
        }

        .form-field {
            display: flex;
            align-items: center;
            gap: 5px;

            // default input
            .input-element {
                position: relative;
                display: flex;
                align-items: center;
                width: 360px;
                height: 44px;
                background-color: #fff;
                border-radius: 5px;
                border: 1px solid $color-gray6;
                transition: border-color 0.3s;
                overflow: hidden;
                padding: 0 8px;

                input {
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    border: none;
                    font-size: 15px;
                    font-weight: 400;
                    color: $color-black;

                    &[name="idNumberB"] {
                        position: absolute;
                        z-index: 2;
                        width: 20px;
                        text-align: center;
                        background: transparent;
                        border: none;
                        margin-left: 10px;
                        // padding-left: 14px;

                        &:focus {
                            outline: none;
                        }

                        &::placeholder {
                            color: transparent;
                        }

                        &:not(:-ms-input-placeholder) {
                            background: #fff;
                        }

                        &:not(:placeholder-shown) {
                            background: #fff;
                        }
                    }
                }

                label.idNumberLabel {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    display: block;
                    margin-left: 10px;
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 16px;
                        left: 30px;
                        z-index: 1;
                        width: 100%;
                        max-width: 106px;
                        height: 40%;
                        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAXCAYAAAD+4+QTAAABRGlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxsDEIMygxWCUmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsisXDHL4E8dzDk8Ja7ZK10eK2OqRwFcKanFyUD6DxCnJhcUlTAwMKYA2crlJQUgdgeQLVIEdBSQPQfEToewN4DYSRD2EbCakCBnIPsGkC2QnJEINIPxBZCtk4Qkno7EhtoLAtxG5p4BwRZmji4eBFxLBihJrSgB0c75BZVFmekZJQqOwFBKVfDMS9bTUTAyMDJiYACFOUT15xvgsGQU40CI5cxnYDBdzMDAxIoQS6pmYNi2C+iNkwgx9T0MDMJAdQfiChKLEuEOYPzGUpxmbARhc29nYGCd9v//53AGBnZNBoa/1////739//+/yxgYmG8B9X4DAEqZXm6PBouGAAAAVmVYSWZNTQAqAAAACAABh2kABAAAAAEAAAAaAAAAAAADkoYABwAAABIAAABEoAIABAAAAAEAAAAZoAMABAAAAAEAAAAXAAAAAEFTQ0lJAAAAU2NyZWVuc2hvdERrfoIAAAHUaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJYTVAgQ29yZSA2LjAuMCI+CiAgIDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+CiAgICAgIDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiCiAgICAgICAgICAgIHhtbG5zOmV4aWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vZXhpZi8xLjAvIj4KICAgICAgICAgPGV4aWY6UGl4ZWxZRGltZW5zaW9uPjIzPC9leGlmOlBpeGVsWURpbWVuc2lvbj4KICAgICAgICAgPGV4aWY6UGl4ZWxYRGltZW5zaW9uPjI1PC9leGlmOlBpeGVsWERpbWVuc2lvbj4KICAgICAgICAgPGV4aWY6VXNlckNvbW1lbnQ+U2NyZWVuc2hvdDwvZXhpZjpVc2VyQ29tbWVudD4KICAgICAgPC9yZGY6RGVzY3JpcHRpb24+CiAgIDwvcmRmOlJERj4KPC94OnhtcG1ldGE+Cp1dxrAAAAFaSURBVEgNzZQxr0RAEMeHHHcXIohcqxe0vn+rFaVCoZAjKIhc8N6Nl0ssb44t7uVNw+z+Z367M9kRvp4GHzbxw/nn9P8XghWepulwEU5HlUVRQJqm0Pc9jOP4UwZRhPP5DLfbDWzbJlMJe43HE8dxDFVVkUlw43K5gO/783ct3IWEYTiffh34my8IAgRBALIsM9tvG58kyWEAZsVeRVHEANAhIY/HA7Is2wTsLbRtC3meMzISUpYlI+Rx7vc7IychdV0zQh6n6zpGTkKGYWCEPA6WemkkZCni/Ref72dprLfY0TRt4fH9KorCBJAQy7IYIY+zPiAJwdOoqsqTe9ZiqdYjhoRghOd5gK+YxxzHgcM9wcSSJIHrupsgCoo3ME1zs707uzACpy4OSert4HDEW1+v1w0AFw5BXpE4m5qmmScyTmdd18EwjN2bckFeMN7v28bzJqP0fwL5BsVceOtTqbNIAAAAAElFTkSuQmCC");
                        background-repeat: repeat-x;
                        background-position: 0px calc(50% - 2px);
                        background-size: 18px auto;
                    }
                }

                input::placeholder {
                    color: $color-gray5;
                }

                input:focus {
                    outline: none;
                }

                &:focus-within {
                    border-color: $color-primary;
                }

                // text_notice (안내말)
                &.text_notice {
                    input {
                        width: auto;
                    }

                    span {
                    }
                }
            }

            .select-element {
                width: 100%;
            }

            // input file
            .file-element {
                position: relative;
                display: flex;
                align-items: center;
                width: 360px;
                height: 48px;
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid $color-black;
                transition: border-color 0.3s;
                overflow: hidden;
                padding: 0 12px;

                input[type="file"] {
                    position: absolute;
                    width: 0;
                    height: 0;
                    padding: 0;
                    overflow: hidden;
                    border: 0;
                }

                .upload_name {
                    color: $color-black;
                    z-index: 1;
                    display: inline-block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: calc(100% - 30px);
                }

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    background-color: transparent;
                    cursor: pointer;

                    img {
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            // number element (6 number)
            .number-element {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0 12px;
                width: 360px;
                height: 48px;
                background-color: #fff;
                transition: border-color 0.3s;
                overflow: hidden;
                padding: 0;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    border: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: transparent;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    opacity: 0;
                }

                input::placeholder {
                    color: $color-gray5;
                }

                input:focus {
                    outline: none;
                }

                .authKeyItems {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    height: 100%;
                    border-bottom: 2px solid $color-gray6;
                    font-size: 20px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.7;
                    letter-spacing: normal;
                    text-align: center;
                    color: $color-black;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                &:focus-within {
                    border-color: $color-primary;
                }
            }

            // buttons with input
            .btn-address {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 112px;
                height: 48px;
                margin-left: 12px;
                border-radius: 6px;
                background-color: $color-primary;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: $color-black;
            }

            .btn-auth {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 112px;
                height: 48px;
                margin-left: 12px;
                border-radius: 6px;
                background-color: $color-primary;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: $color-black;
            }

            .btn-noncash {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 112px;
                height: 48px;
                margin-left: 12px;
                border-radius: 6px;
                background-color: $color-primary;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: $color-black;
            }

            .toggleButtonWrapper {
                display: flex;
                align-items: center;
                gap: 2px;
                width: 100%;

                .toggleButton {
                    flex: 1;
                    height: 48px;
                    border-radius: 8px;
                    border: solid 1px #eaeaea;
                    background-color: #fff;
                    transition: border-color 0.15s;

                    &.active {
                        border: solid 1px #fec108;
                        background-color: #fff;
                    }
                }
            }
        }

        // auth info & buttons
        .auth-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 17px;

            .remain_time {
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: $color-gray1;
            }

            button {
                padding: 4px 16px;
                border: 1px solid $color-gray5;
                background-color: #fff;
                border-radius: 6px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: normal;
                text-align: center;
                color: $color-gray1;
            }
        }

        .select-element {
            position: relative;
            z-index: 11;

            .selectOption {
                > div {
                    border-radius: 8px;
                }
            }
        }

        // disabled
        &.disabled .input-element {
            background-color: $color-gray6;
            border-color: $color-gray6;

            input {
                color: $color-gray4;
                pointer-events: none;
                cursor: auto;
            }
        }

        &.disabled .select-element {
            .selectOption {
                > div {
                    pointer-events: none;
                    background-color: $color-gray6;
                    border-color: $color-gray6;
                    color: $color-gray4;
                    pointer-events: none;
                    cursor: auto;

                    * {
                        color: #9f9f9f !important;
                    }
                }
            }
        }

        &.disabled .file-element {
            background-color: $color-gray6;
            border-color: $color-gray6;
            pointer-events: none;

            .upload_name {
                color: $color-gray4;
                pointer-events: none;
                cursor: auto;
            }

            label {
                img {
                    display: none;
                }
            }
        }

        &.disabled .btn-address {
            background-color: $color-gray6;
            border-color: $color-gray6;
            color: $color-gray4;
            cursor: default;
            pointer-events: none;
        }

        &.disabled .btn-auth {
            background-color: $color-gray6;
            border-color: $color-gray6;
            color: $color-gray4;
            cursor: default;
            pointer-events: none;
        }

        // alert effects
        &.error .input-element {
            border-color: $color-danger;
        }

        &.success .input-element {
            border-color: $color-susscess;
        }

        .alert-msg {
            display: none;
            font-size: 12px;
            font-weight: 400;
            margin-top: 4px;

            &.error {
                display: block;
                color: $color-danger;
            }

            &.success {
                display: block;
                color: $color-susscess;
            }

            &.timer {
                display: block;
                color: $color-gray1;
            }
        }

        // certification button
        .btn-certification {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 64px;
            height: 28px;
            background-color: $color-gray6;
            border: 1px solid $color-gray6;
            border-radius: 4px;
            padding: 0;
            font-size: 12px;
            color: $color-gray4;
            cursor: pointer;

            &.completed {
                background-color: $color-primary;
                border-color: $color-primary;
                color: $color-black;
            }

            &.certified {
                background-color: $color-white;
                border-color: $color-primary;
                color: $color-black;
            }
        }

        // file image (독립)
        .form-image {
            margin-top: 12px;

            .image-item {
                position: relative;
                background-color: #e8e8e8;
                width: 100%;
                height: 220px;
                border-radius: 12px;
                border: 1px solid #e8e8e8;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .image-empty {
                position: relative;
                background-color: #e8e8e8;
                width: 100%;
                height: 220px;
                border-radius: 12px;
                border: 1px solid #e8e8e8;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: "";
                    width: 24px;
                    height: 24px;
                    background: url("../../resources/icon/icon-camera.svg");
                }
            }
        }

        // file image (멀티플)
        .formImage {
            display: flex;
            gap: 12px;
            flex-wrap: wrap;
            margin-top: 20px;
            padding-bottom: 4px;

            .imgItem {
                position: relative;
                width: 100%;
                padding: 12px;
                flex-grow: 0;
                flex-shrink: 0;
                opacity: 0.8;
                border-radius: 4px;
                background-color: #d9d9d9;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .btnRemoveImg {
                    content: "";
                    position: absolute;
                    top: 10px;
                    right: 6px;
                    width: 16px;
                    height: 16px;
                    background: url("../../resources/icon/icon-remove_white.svg") center center no-repeat;
                    background-size: cover;
                }
            }

            .image-empty {
                position: relative;
                background-color: #e8e8e8;
                width: 188px;
                height: 188px;
                border-radius: 12px;
                border: 1px solid #e8e8e8;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    content: "";
                    width: 24px;
                    height: 24px;
                    // background: url("../../assets/icons/icon-camera.svg");
                }
            }
        }

        .form-field {
            display: flex;
            align-items: center;

            // default input
            // .input-element {
            //     display: flex;
            //     align-items: center;
            //     width: 360px;
            //     max-width: 100%;
            //     height: 48px;
            //     background-color: #fff;
            //     border-radius: 8px;
            //     border: 1px solid var(--gray-scale-gray-3);
            //     transition: border-color 0.3s;
            //     overflow: hidden;
            //     padding: 0 16px;

            //     & ~ .select-element {
            //         width: 94px;
            //         flex-shrink: 0;
            //         margin-left: 10px;
            //     }

            //     input {
            //         width: 100%;
            //         height: 100%;
            //         background: transparent;
            //         border: none;
            //         font-size: 14px;
            //         font-weight: 400;
            //         color: var(--gray-scale-black);
            //     }

            //     input::placeholder {
            //         color: var(--gray-scale-gray-4);
            //     }

            //     input:focus {
            //         outline: none;
            //     }

            //     &:focus-within {
            //         border-color: var(--primary-primary);
            //     }

            //     // text_notice (안내말)
            //     &.text_notice {
            //         input {
            //             width: auto;
            //         }

            //         span {}
            //     }

            //     .input-asset {
            //         flex-shrink: 0;
            //         font-size: 14px;
            //         font-weight: normal;
            //         font-stretch: normal;
            //         font-style: normal;
            //         line-height: 1.7;
            //         letter-spacing: normal;
            //         text-align: right;
            //         color: var(--gray-scale-gray-7);
            //     }
            // }

            // input file
            .file-element {
                position: relative;
                display: flex;
                align-items: center;
                width: 360px;
                max-width: 100%;
                height: 48px;
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid var(--gray-scale-black);
                transition: border-color 0.3s;
                overflow: hidden;
                padding: 0 16px;

                input[type="file"] {
                    position: absolute;
                    width: 0;
                    height: 0;
                    padding: 0;
                    overflow: hidden;
                    border: 0;
                }

                .upload_name {
                    color: var(--gray-scale-black);
                    z-index: 1;
                    display: inline-block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: calc(100% - 30px);
                }

                label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    background-color: transparent;
                    cursor: pointer;

                    img {
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            // number element (6 number)
            .number-element {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0 16px;
                width: 360px;
                max-width: 100%;
                height: 48px;
                background-color: #fff;
                transition: border-color 0.3s;
                overflow: hidden;
                padding: 0;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    border: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: transparent;
                }

                input::placeholder {
                    color: var(--gray-scale-gray-4);
                }

                input:focus {
                    outline: none;
                }

                .authKeyItems {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    height: 100%;
                    border-bottom: 2px solid var(--gray-scale-gray-3);
                    font-size: 20px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.7;
                    letter-spacing: normal;
                    text-align: center;
                    color: var(--gray-scale-black);
                }

                &:focus-within {
                    border-color: var(--primary-primary);
                }
            }

            // buttons with input
            .btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 94px;
                height: 48px;
                margin-left: 10px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: var(--primary-primary);
                padding: 0;
                cursor: pointer;

                &.btnSelectImage {
                    margin-left: 0;
                    width: 100%;
                    border-color: #222;

                    span {
                        color: var(--primary-primary);
                    }
                }

                &.btnFull {
                    width: 100%;
                    margin-left: 0;
                }

                &.primaryFill {
                    color: var(--gray-scale-white);
                }
            }

            &:not(:first-child) {
                margin-top: 8px;
            }

            // disabled
            &.disabled .input-element {
                border-color: var(--gray-scale-gray-3);
                background-color: var(--gray-scale-gray-2);

                input {
                    color: var(--gray-scale-gray-4);
                    pointer-events: none;
                    cursor: auto;
                }
            }

            // alert effects
            &.error .input-element {
                border-color: var(--alert);
            }

            &.success .input-element {
                border-color: var(--sub-color);
            }

            // &+.alert-msg {
            //     display: none;
            //     font-size: 12px;
            //     font-weight: 400;
            //     margin-top: 4px;
            // }

            // &.error+.alert-msg.error {
            //     display: block;
            //     color: var(--alert);
            // }

            // &.success+.alert-msg.success {
            //     display: block;
            //     color: var(--sub-color);
            // }

            // &.timer+.alert-msg.timer {
            //     display: block;
            //     color: var(--gray-scale-gray-1);
            // }

            // default textarea
            .textarea-element {
                display: flex;
                align-items: center;
                width: 360px;
                max-width: 100%;
                height: 100%;
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid var(--gray-scale-gray-3);
                transition: border-color 0.3s;
                overflow: hidden;
                padding: 16px;

                textarea {
                    width: 100%;
                    height: 280px;
                    max-height: 100%;
                    background: transparent;
                    border: none;
                    font-family: PretendardVariable;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.7;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--gray-scale-gray-7);
                    resize: none;
                    white-space: pre-wrap;
                }

                textarea::placeholder {
                    font-family: PretendardVariable;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.7;
                    letter-spacing: normal;
                    text-align: left;
                    color: var(--gray-scale-gray-4);
                }

                textarea:focus {
                    outline: none;
                }

                &:focus-within {
                    border-color: var(--primary-primary);
                }
            }
        }
    }

    .addedInfoBox {
        position: relative;
        background-color: #f9fafc;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;

        .btnRemove {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
            z-index: 5;
        }
    }

    // range
    // input[type="range"] {
    //   overflow: hidden;
    //   height: 30px;
    //   -webkit-appearance: none;
    //   margin: 10px 0;
    //   width: 100%;
    //   background: transparent;
    // }

    // input[type="range"]:focus {
    //   outline: none;
    // }

    // input[type="range"]::-webkit-slider-runnable-track {
    //   width: 100%;
    //   height: 100%;
    //   cursor: pointer;
    //   border-radius: 5px;
    //   border: 2px solid #ff96ab;
    // }

    // input[type="range"]::-webkit-slider-thumb {
    //   -webkit-appearance: none;
    //   width: 30px;
    //   height: 30px;
    //   background: #ff494f;
    //   box-shadow: 1px 1px 7px #d16a6e;
    //   cursor: pointer;
    //   box-shadow: -100vw 0 0 100vw #ff96ab;
    // }

    // Selectbox
    .css-1okebmr-indicatorSeparator {
        width: 0 !important;
    }

    .css-tlfecz-indicatorContainer {
        color: #000 !important;
    }

    .css-bgy71k-singleValue {
        overflow: unset !important;
    }

    .option_remove {
        background-color: red !important;
        color: red !important;
        font-size: 32px !important;
    }

    // Checkbox
    .checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        label {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
        }

        input {
            display: none;
        }

        input:checked ~ div {
            background: url("../../resources/icon/checkbox_active.svg") center center no-repeat;
            background-size: contain;
        }

        input:checked ~ span {
            color: #000;
        }

        div {
            // display: inline-block;
            // width: 24px;
            // height: 24px;
            // background: url("../../resources/icon/checkbox.svg") center center no-repeat;
            // background-size: contain;
            // cursor: pointer;
        }

        button {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: $color-gray1;
            text-decoration: underline;
            margin-left: 16px;
        }
    }

    // Radio button
    .radiobox {
        position: relative;

        input[type="radio"] {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }

        input[type="radio"] + label {
            display: inline-block;
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: $color-gray3;
        }

        input[type="radio"]:checked + label {
            color: $color-black;
        }

        input[type="radio"] + label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            text-align: center;
            background-color: #fff;
            border: 1px solid $color-gray3;
            border-radius: 100%;
        }

        input[type="radio"] + label:before,
        input[type="radio"] + label:after {
            transition: border-color 0.35s, background-color 0.35s;
        }

        input[type="radio"] + label:active:before,
        input[type="radio"]:checked + label:active:before {
        }

        input[type="radio"]:checked + label:before {
            background-color: #fff;
            border-color: $color-primary;
        }

        input[type="radio"]:checked + label:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            width: 10px;
            height: 10px;
            background-color: $color-primary;
            border-radius: 50%;
        }
    }

    // loader
    .loader,
    .loader:after {
        border-radius: 50%;
        width: 8em;
        height: 8em;
    }

    .loader {
        margin: 60px auto;
        font-size: 10px;
        position: fixed;
        top: calc(50% - 80px);
        left: 50%;
        text-indent: -9999em;
        background-color: transparent;
        border-top: 0.8em solid rgba(0, 0, 0, 0.2);
        border-right: 0.8em solid rgba(0, 0, 0, 0.2);
        border-bottom: 0.8em solid rgba(0, 0, 0, 0.2);
        border-left: 0.8em solid $color-primary;
        -webkit-transform: translateZ(0) translateX(-50%) translateY(-50%);
        -ms-transform: translateZ(0) translateX(-50%) translateY(-50%);
        transform: translateZ(0) translateX(-50%) translateY(-50%);
        -webkit-animation: load 1.1s infinite linear;
        animation: load 1.1s infinite linear;
    }

    @-webkit-keyframes load {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

/****************************** UI Components ******************************/
// Button

/****************************** LIBRARY ******************************/
// swiper - pagination
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 28px;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 5.5px !important;
    opacity: 0.5;
    background-color: $color-gray4;

    &.bullet-primary {
        background-color: $color-white;
    }
}

.swiper-pagination-bullet-active {
    background-color: $color-primary;
    opacity: 1;
}

// swiper - navigation
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    opacity: 1;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    background-color: $color-white;
    transition: opacity 0.35s, box-shadow 0.35s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    opacity: 1;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: "";
    width: 24px;
    height: 24px;
    background: url("../../resources/icon/depth-sm-gr.svg") center center no-repeat;
    transform: rotate(180deg);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    opacity: 1;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    background-color: $color-white;
    transition: opacity 0.35s, box-shadow 0.35s;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after .swiper-button-prev:hover {
    opacity: 1;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: "";
    width: 24px;
    height: 24px;
    background: url("../../resources/icon/depth-sm-gr.svg") center center no-repeat;
}

// Swiper - Thumb
.swiperThumb .swiper-slide {
    width: 100%;
}

.swiperThumb img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.swiperThumb .swiper-slide-thumb-active {
    img {
        border: 3px solid $color-primary;
    }
}

.swiperThumbMain .swiper-pagination {
    width: auto;
    text-align: right;
    left: unset;
    right: 28px;
    bottom: 28px;
    padding: 3px 8px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 12px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: $color-gray4;

    .swiper-pagination-current {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $color-white;
    }

    .swiper-pagination-total {
    }
}

.swiper-slide {
    text-align: center;
}

.swiper-slide img {
    width: 100%;
}

// MUI - Slider
.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
    box-shadow: none !important;
}

.MuiSlider-thumb {
    margin-top: -10px !important;
    width: 20px !important;
    height: 20px !important;
    background-color: $color-white !important;
    border: 2px solid $color-primary !important;
}

.MuiSlider-track {
    background-color: $color-primary !important;
}

// commons
.hidePrice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .hp1 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.64;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    .hp2 {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
}

.hidePrice2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .hp1 {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    .hp2 {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        text-align: left;
        color: #666;
    }
}

.filterOptionWrapper {
    position: relative;
    flex: 1;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: transparent;
    box-sizing: border-box;
    width: 100%;
    height: 20px;
    background: white;

    .filterOptionHead {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 2px 8px;

        .placeholder {
            color: hsl(0, 0%, 50%);
        }

        .value {
            color: #222;
        }

        .fowIcon {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-transition: color 150ms;
            transition: color 150ms;
            color: hsl(0, 0%, 80%);
            padding: 8px;
            box-sizing: border-box;
        }

        svg {
        }

        &:hover {
            svg {
                color: hsl(0, 0%, 60%);
            }
        }

        &.active {
            svg {
                color: #222;
            }
        }
    }

    .filterOptionContent {
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
