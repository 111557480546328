/* 폰트 참조 - Pretendard */
@font-face {
    font-family: Pretendard;
    font-weight: 900;
    font-display: swap;
    src: local('Pretendard Black'),
        url('../fonts/Pretendard-Black.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 800;
    font-display: swap;
    src: local('Pretendard ExtraBold'),
        url('../fonts/Pretendard-ExtraBold.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 700;
    font-display: swap;
    src: local('Pretendard Bold'),
        url('../fonts/Pretendard-Bold.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 600;
    font-display: swap;
    src: local('Pretendard SemiBold'),
        url('../fonts/Pretendard-SemiBold.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 500;
    font-display: swap;
    src: local('Pretendard Medium'),
        url('../fonts/Pretendard-Medium.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 400;
    font-display: swap;
    src: local('Pretendard Regular'),
        url('../fonts/Pretendard-Regular.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 300;
    font-display: swap;
    src: local('Pretendard Light'),
        url('../fonts/Pretendard-Light.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 200;
    font-display: swap;
    src: local('Pretendard ExtraLight'),
        url('../fonts/Pretendard-ExtraLight.woff2') format('woff2'),
}

@font-face {
    font-family: Pretendard;
    font-weight: 100;
    font-display: swap;
    src: local('Pretendard Thin'),
        url('../fonts/Pretendard-Thin.woff2') format('woff2'),
}

/* 폰트 참조 - Gmarket Sans */
@font-face {
    font-family: "Gmarket Sans";
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/GmarketSansTTFLight.ttf'),
}

@font-face {
    font-family: "Gmarket Sans";
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/GmarketSansTTFMedium.ttf'),
}

@font-face {
    font-family: "Gmarket Sans";
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/GmarketSansTTFBold.ttf'),
}

/* 폰트 참조 - Noto Sans */
@font-face {
    font-family: "Noto Sans";
    font-weight: 100;
    font-display: swap;
    src: url('../fonts/NotoSansKR-Thin.otf'),
}

@font-face {
    font-family: "Noto Sans";
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/NotoSansKR-Light.otf'),
}

@font-face {
    font-family: "Noto Sans";
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/NotoSansKR-Regular.otf'),
}

@font-face {
    font-family: "Noto Sans";
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/NotoSansKR-Medium.otf'),
}

@font-face {
    font-family: "Noto Sans";
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/NotoSansKR-Bold.otf'),
}

@font-face {
    font-family: "Noto Sans";
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/NotoSansKR-Black.otf'),
}


h1 {
    font-family: var(--font-family);
    font-size: 48px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

h2 {
    font-family: var(--font-family);
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

h3 {
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.H4Bold {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.H4Medium {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

h5 {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.Body1Semibold {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.Body1Regualr {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.Body2Medium {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.Body2Regular {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.CaptionMedium {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.CaptionRegular {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.CaptionCaption2 {
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}


@media (max-width: 767px) {
    h2 {
        font-size: 24px;
    }

    .CaptionRegular {
        font-size: 10px;
    }
}