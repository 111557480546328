// Terms
.termsWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #cdcdcd;

    >p {}

    .txtBox {
        width: 100%;
        height: 220px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        padding: 16px;
        border-radius: 8px;
        background-color: var(--gray-scale-gray-1);

        >p {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: var(--gray-scale-gray-7);
            white-space: pre-wrap;
        }
    }

    .termsChkList {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-height: 400px;

        .checkbox {
            position: relative;
            justify-content: flex-start;
            padding: 0 20px;

            label {
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;

                input {
                    display: none;
                }

                div {
                    flex-shrink: 0;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border: none;
                    background: url("/resources/icon/check.svg") center center / cover no-repeat;
                    cursor: pointer;
                }

                span {
                    display: inline-block;
                    margin-left: 10px;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: left;
                    color: #0C247B;
                }

                input:checked~div {
                    background: url("/resources/icon/check_active.svg") center center / cover no-repeat;
                }
            }

            a {
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                display: inline-block;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: right;
                color: var(--gray-scale-gray-4);
                cursor: pointer;
            }

            &.chkHeader {
                padding: 8px 20px;
                border-bottom: 1px solid #cdcdcd;

                div {
                    flex-shrink: 0;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border: none;
                    background: url("/resources/icon/check-total.svg") center center / cover no-repeat;
                    cursor: pointer;
                }

                span {
                    display: inline-block;
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                    color: #0C247B;
                }

                input:checked~div {
                    background: url("/resources/icon/check-total_active.svg") center center / cover no-repeat;
                }
            }
        }
    }
}

// Signature
.signBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--gray-scale-gray-1);

    .btnRemove {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .sign {
        position: relative;
        width: 100%;
        height: 160px;
        border: solid 1px var(--gray-scale-gray-2);
        background-color: var(--gray-scale-white);
    }
}

// react-signature-canvas
.canvasStyle {
    width: 100%;
    height: 100%;
}