.custom-select {
    width: 100%;
    // z-index: 100;
}

.custom-select__menu {
    // border: 4px dashed red;
}

// control
.custom-select__control {
    width: 100%;
    border: 1px solid red;
    border-radius: 0;
    border: none !important;
    padding: 0;
    box-shadow: unset !important;

    &:hover {
        border-color: unset;
    }
}

.custom-select__value-container {
    padding: 0 !important;
    border: none;
}

.custom-select__control--is-focused {
}

.custom-select__control--menu-is-open {
}

.custom-select--is-disabled,
.custom-select__control--is-disabled {
    background-color: #fff !important;
}

// placeholder
.custom-select__placeholder {
    margin: 0 !important;
    font-size: 15px;
    font-weight: 400;
    color: var(--gray-scale-gray-9) !important;
}

// indicator
.custom-select__indicator-separator {
    display: none;
}

// options
.custom-select__menu {
    min-width: calc(100% + 16px) !important;
    width: max-content !important;
    left: -8px !important;
    top: calc(100% + 4px) !important;
    margin: 0 !important;
}

.custom-select__option {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #6b6b6b !important;
    background-color: #fff !important;

    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }
}

.custom-select__option--is-focused {
    color: #fff;
    border: none;
    outline: none;
}

// selected options
.custom-select__option--is-selected {
    background-color: #fdc33b !important;
    color: #fff !important;
}

.custom-select__option--is-selected:hover {
    background-color: #fdc33b !important;
}

.custom-select__option:hover {
    background-color: rgba($color: #fdc02f, $alpha: 1) !important;
    color: #fff !important;
}

// value
.custom-select__single-value {
    margin: 0 !important;
    font-size: 15px;
    font-weight: 400;
    color: var(--gray-scale-black);
}

.custom-select__input-container {
    padding: 0 !important;
    margin: 0 !important;
}
