/* Modal.css */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;

    &.invisible {
        background-color: transparent;
    }

    .modal {
        width: 372px;
        height: 200px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background: white;
        border-radius: 8px;
    }

    .modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        height: 150px;
        text-align: center;
        padding: 0 24px;
    }

    .modal-content h2 {
        font-size: 20px;
        font-weight: 700;
    }

    .modal-content p {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.44;
    }

    .modal-buttons {
        display: flex;
        height: 50px;
        border-top: 1px solid #eaeaea;
    }

    .modal-buttons button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
        flex: 1;
    }

    .modal-buttons button:not(:first-child) {
        border-left: 1px solid #eaeaea;
    }

    .modal-buttons button:first-child {
        color: #fdc02f;
    }
}
