/****************************** Reset ******************************/

* {
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 62.5%;
    font: inherit;
    vertical-align: baseline;
    font-family: var(--font-family);
    color: var(--gray-scale-black);
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
}

ol,
ul,
li {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background: none;
}

::-webkit-scrollbar-thumb {
    background: var(--gray-scale-gray-3);
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a:visited {
    color: inherit;
}

button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="text"],
input[type="number"],
input[type="date"] {
    width: inherit;
    border: none;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--gray-scale-black);
}

input[type="text"]::placeholder {
    color: var(--gray-scale-gray-4);
}

textarea::placeholder {
    color: var(--gray-scale-gray-4);
}

select,
option,
input,
textarea,
button {
    font-family: var(--font-family);
    padding: 0;
    outline: none;
}
