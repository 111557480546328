:root {
  --font-family: "Noto Sans", sans-serif;
  --font-sub: "Gmarket Sans", sans-serif;
  --primary-primary: #fcc02f;
  --primary-100: #fdc02f;
  --primary-200: #262d40;
  --sub-color: #fb3b3b;
  --gray-scale-black: #000;
  --gray-scale-white: #fff;
  --gray-scale-gray-1: #f9fafc;
  --gray-scale-gray-2: #f9f9f9;
  --gray-scale-gray-3: #e4e6ea;
  --gray-scale-gray-4: #9da0a8;
  --gray-scale-gray-5: #7f828c;
  --gray-scale-gray-6: #5a5e6a;
  --gray-scale-gray-7: #3b3f4a;
  --gray-scale-gray-8: #dee4f0;
  --gray-scale-gray-9: #cdcdcd;
  --gray-scale-gray-10: #808080;
  --gray-scale-gray-11: #dadada;
  --gray-scale-gray-12: #e9e9e9;
  --gray-scale-gray-13: #e4e9f2;
  --none: rgba(255, 255, 255, 0);
  --alert: #ff2f2f;
  --subcolor: #f9a825;

  --headerHeight: 0px;

  @media (max-width: 767px) {
    --headerHeight: 0px;
  }
}
